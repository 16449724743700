import React from 'react'
import { Link } from 'react-router-dom'

const OurServices = () => {

  return (
    <section className='site-wrapper index-section'>
      <div className='our-services'>
        <img src={require("../../assets/images/d7.png")} alt="OUR SERVICES" />
        <div>
          <div className='section-top'>
            <span>— OUR SERVICES</span>
            <h3 style={{ marginBottom: "20px" }}>Your comfort is our priority</h3>
            <p>Aided by technical knowledge & creative design, we offer a range of services to practically implement your idea.</p>
          </div>
          <div className='our-services-list'>
            <div><div className='our-service-ball'></div><span>Floor Plan</span></div>
            <div><div className='our-service-ball'></div><span>Interior Design</span></div>
            <div><div className='our-service-ball'></div><span>Exterior Design & Views/ Elevations</span></div>
            <div><div className='our-service-ball'></div><span>Structural Plan</span></div>
            <div><div className='our-service-ball'></div><span>MEP Drawings</span></div>
            <div><div className='our-service-ball'></div><span>BoQ and other add-on services</span></div>
          </div>
          <button className='button' style={{ padding: "10px 50px" }}><Link to="/product-page">See More</Link></button>
        </div>
      </div>
    </section>
  )
}

export default OurServices