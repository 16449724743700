import React, { Fragment, useState } from 'react';
import Layout from '../components/layout';
import { Form } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { createContactUs } from '../components/Redux/Home/action';
import { toast, ToastContainer } from 'react-toastify';
import Svgloader from '../components/svgloader';


const Contact = () => {
  const [sent, setSent] = useState("")
  const [loading, setLoading] = useState(false)
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Required'),

    email: Yup.string().email()
      .required('Required'),
    phone: Yup.number()
      .required('Required'),
    message: Yup.string()
      .required('Required'),

  });
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
    },
    validationSchema,
    onSubmit: (values) => {
      setLoading(true)
      let formData = new FormData();
      formData.append('name', values.name);
      formData.append('email', values.email);
      formData.append('phone_number', values.phone);
      formData.append('message', values.message);
      createContactUs({ formData })((response) => {
        if (response && response.status) {
          formik.handleReset();
          setSent(true)
          setLoading(false)
          toast.success("Message sent successfully", {
            position: toast.POSITION.TOP_RIGHT
          });
        }
        else {
          setLoading(false)
          toast.error("Something went wrong", {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      });

    },
  });
  return (
    <Layout>
      <ToastContainer style={{ zIndex: 99999 }} />
      <div className='site-wrapper'>
        <section className='page-head'>
          <span>Contact Us</span>
          <h2>How Can we help you ?</h2>
          <p>Fill in the form or drop an email</p>
        </section>
        <section className='contact-details'>
          <div className='contact-info-wrapper'>
            <h3>Get in touch</h3>
            <div className='contact-info'>
              <div><img src={require("../assets/images/c1.png")} alt='email-icon' /></div>
              <div>
                <p>stikcon@gmail.com</p>
              </div>
            </div>
            <div className='contact-info'>
              <div><img src={require("../assets/images/c2.png")} alt='number-icon' /></div>
              <div>
                <p>+91 8899002211</p>
                <p>+91 8899002211</p>
              </div>
            </div>
            <div className='contact-info'>
              <div><img src={require("../assets/images/c3.png")} alt='address-icon' /></div>
              <div>
                <p>8th Floor, Bizcospaces,Infra Futura Building,Seaport Airport Road, Kakkanad, Kochi, Kerala-682021</p>
              </div>
            </div>
          </div>

          <div>
            <Form onSubmit={formik.handleSubmit}>
              <div className='contact-form'>
                <Form.Group controlId="formBasicText">
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="text" name='name' value={formik.values.name} onChange={formik.handleChange} isInvalid={formik.errors?.name ? true : false} />
                </Form.Group>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" name='email' value={formik.values.email} onChange={formik.handleChange} isInvalid={formik.errors?.email ? true : false} />
                </Form.Group>
                <Form.Group controlId="formBasicText">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control type="text" name='phone' value={formik.values.phone} onChange={formik.handleChange} isInvalid={formik.errors?.phone ? true : false} />
                </Form.Group>
                <Form.Group controlId="ControlTextarea1">
                  <Form.Label>Message</Form.Label>
                  <Form.Control as="textarea" rows={4} name='message' value={formik.values.message} onChange={formik.handleChange} isInvalid={formik.errors?.message ? true : false} />
                </Form.Group>
              </div>
              {loading ?
                <Fragment>
                  <Svgloader />
                </Fragment>
                :
                <button className='button form-btn' type='submit'>Send</button>
              }
              {sent && (
                <p className='sent-text'>
                  Thank you the inquiry, we will be in touch with you shortly
                </p>
              )}
            </Form>
          </div>
        </section>
      </div>
    </Layout>
  )
};

export default Contact;