import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripLines, faAngleDown, faXmark, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import LoginAndSignup from './login-and-signup';
import { Link } from 'react-router-dom'
import Notification from "./notification";
import { listSolutions } from "../Redux/Home/action";
import { getAllProductDetails } from "../Redux/Product/action";
import { BASE_URL } from "../api";

const Header = (props) => {
  const { showLogin, setShowLogin, setUpdate, update, } = props;
  const [solution, setSolution] = useState(false)
  const [showProduct, setShowProduct] = useState(false)
  const [data, setData] = useState([])
  const [products, setProducts] = useState([])
  useEffect(() => {
    if (showLogin && showLogin !== undefined) {
      setIsOpen(true)
    }
  }, [showLogin])
  useEffect(() => {
    listSolutions()((response) => {
      if (response && response.status) {
        setData(response?.data?.results)
      }
    });
    getAllProductDetails(1, 5)((response) => {
      if (response && response.status) {
        setProducts(response?.data?.results)

      }

    });
  }, [])


  // For logged interface set log logged = "logged"
  const logged = localStorage.getItem('is_logged')
  // const logged = "logged"
  const user = JSON.parse(localStorage.getItem('user'))

  const navigate = useNavigate();

  // Mobile menu icon states
  const [click, setClick] = React.useState(false);
  const handleClick = () => setClick(!click);


  // Login states
  const [isOpen, setIsOpen] = useState(false);
  const togglePopup = () => {
    setIsOpen(!isOpen);
    if (setShowLogin)
      setShowLogin(false)
    setUpdate(!update)
  }

  // Menu states
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const toggleMeunPopup = () => {
    setIsOpenMenu(!isOpenMenu);

  }

  let menuRef = useRef()
  let solutionRef = useRef()
  let productRef = useRef()
  useEffect(() => {
    let handler = (event) => {
      if (menuRef && menuRef.current) {
        if (!menuRef.current.contains(event.target)) {
          setIsOpenMenu(false);
        }
      }
      if (solutionRef && solutionRef.current) {
        if (!solutionRef.current.contains(event.target)) {
          setSolution(false);
        }
      }
      if (productRef && productRef.current) {
        if (!productRef.current.contains(event.target)) {
          setShowProduct(false);
        }
      }
    }
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const [expanded, setExpanded] = useState(false)
  const logoutCustomer = () => {
    localStorage.clear();
    navigate('/')
  }
  return (
    <header>
      <nav className="header">
        <div className="nav-container">
          <NavLink exact to="/" className="nav-logo">
            <img src={require("../../assets/images/stikcon-logo.png")} alt='logo' style={{ width: "150px", height: "40px" }} />
          </NavLink>
          <ul className={click ? "nav-menu active" : "nav-menu"}>

            {/* ----- Mobile responsive menu ------ */}
            <div className="mobile-res-menu">
              <h3>Menu</h3>
              <FontAwesomeIcon icon={faXmark} onClick={() => setClick(!click)} />
            </div>

            {/* ----- If logged ------ */}
            {logged && (
              <div className="profile-accordion">
                <div className="profile-accordion-title">
                  <div onClick={() => setExpanded(!expanded)} style={{ display: "flex", gap: "10px" }}>
                    <img src={user ? user?.image : require("../../assets/images/t1.png")} alt='Profile'
                      style={{ width: "30px", height: "30px", borderRadius: "50%" }}
                    />
                    <h3>Profile</h3>
                  </div>
                  <button className='faq-btn' onClick={() => setExpanded(!expanded)}>
                    {expanded ? <FontAwesomeIcon icon={faMinus} /> : <FontAwesomeIcon icon={faPlus} />}
                  </button>
                </div>
                {expanded &&
                  <div className="profile-accordion-links" ref={menuRef}>
                    <Link to="/my-favourites">Favourite</Link>
                    <Link to="/profile">Profile</Link>
                    <Link to="/my-designs">My Designs</Link>
                    <Link to="/payments">Payments</Link>
                    <Link to="/" onClick={() => logoutCustomer()}>Logout</Link>
                  </div>
                }
              </div>
            )}

            {/* ----- If not logged ------ */}
            {/* ----- Starts Site Nav links ------ */}
            <li>
              <NavLink exact to="/">Home</NavLink>
            </li>
            <li className="product-menu">
              <div className="a-content" onClick={() => setShowProduct(!showProduct)}>Products</div>
              {showProduct &&
                <div className="product-popup" ref={productRef}>
                  <div className="row">
                    {products?.map(product =>
                      <a href={BASE_URL + '/product-details-page/' + product?.id} className="col-lg-12 mt-2" >{product?.title}</a>
                    )}
                    <hr/>
                    <Link to={'/product-page/'} >View All</Link>
                  </div>
                </div>
              }
            </li>
            <li>
              <div className="a-content" onClick={() => setSolution(!solution)}>Solution</div>
              {solution &&
                <div className="solution-popup" ref={solutionRef}>
                  <div className="row">
                    {data?.map(sol =>
                      <a href={sol?.link} className="col-lg-12 mt-2" >{sol?.title}</a>
                    )}
                  </div>
                </div>
              }
            </li>
            <li>
              <NavLink exact to="/faq">FAQ</NavLink>
            </li>
            <li>
              <NavLink exact to="/contact">Contact Us</NavLink>
            </li>
            {/* ----- Ends Site Nav links ------ */}

            {/* ----- Starts Logged Profile (or) Login button ------ */}
            {logged === "logged" ? (
              <li className="logged-profile-res">
                <Notification />
                <img src={user ? user?.image : require("../../assets/images/t1.png")} alt='Profile'
                  className="profile-img"
                />
                <FontAwesomeIcon icon={faAngleDown} onClick={toggleMeunPopup} style={{ cursor: "pointer", fontSize: "20px" }} />
                {isOpenMenu &&
                  <div className="menu-popup" ref={menuRef}>
                    <div className="menu-popup-links">
                      <Link to="/my-favourites">Favourite</Link>
                      <Link to="/profile">Profile</Link>
                      <Link to="/my-designs">My Designs</Link>
                      <Link to="/payments">Payments</Link>
                      <div className="log_out" onClick={() => logoutCustomer()}>Logout</div>
                    </div>
                  </div>
                }
              </li>
            ) : (
              <>
                <input
                  type="button"
                  value="Login"
                  className="button logged-btn"
                  onClick={togglePopup}
                />
                {isOpen && <LoginAndSignup handleClose={togglePopup} setUpdate={setUpdate} />}
              </>
            )}
            {/* ----- Ends Logged Profile (or) Login button ------ */}
          </ul>

          {/* ----- Starts Mobile view icons  ------ */}
          <div style={{ display: "flex", alignItems: "center", gap: "20px", marginRight: "10px" }}>
            <div className="nav-icon-bell">
              <Notification />
            </div>
            <div className="nav-icon" onClick={handleClick}>
              <FontAwesomeIcon icon={faGripLines} />
            </div>
          </div>
          {/* ----- Ends Mobile view icons  ------ */}
        </div>

      </nav>
    </header>
  )
}

export default Header