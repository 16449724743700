import React from 'react'
import Layout from '../components/layout'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { listBrochure } from '../components/Redux/Home/action';

const AboutUs = () => {
  const background = require("../assets/images/about-bg.png")
  const downloadBrochure = () => {
    listBrochure()((response) => {
      if (response && response.status) {
        let image = response.data?.results[0]?.file
        image = image.replace('http://', 'https://');
        console.log(image);
        fetch(image)
          .then(response => {
            response.blob().then(blob => {
              let url = window.URL.createObjectURL(blob);
              let a = document.createElement('a');
              a.href = url;
              a.download = 'brochure';
              a.click();
            });
          });
      }
    });

  }
  return (
    <Layout>
      <div>
        <div style={{ backgroundImage: `url(${background})` }} className="about-bg">
          <div className='about-info'>
            <h2>About Us</h2>
            <div>
              <p>Nibh quisque suscipit fermentum netus nulla cras porttitor euismod nulla. Orci, dictumst nec aliquet id ullamcorper</p>
              <button onClick={downloadBrochure}><FontAwesomeIcon icon={faDownload} /> Download Brochure</button>
            </div>
          </div>
        </div>
        <section className='about-cards'>
          <div className='about-card'>
            <img src={require("../assets/images/d1.png")} alt='About-us' className='about-img left-image'
            />
            <div>
              <span>— ABOUT US</span>
              <h3>Your trust is our
                Responsibility</h3>
              <p>Stikcon founded by Top-notch technical and business acumen with a track record in AEC(Architectural Engineering and Construction) Industry</p>
              <p>Our team includes architects, designers,Civil Engineers, MEP engineers, and data scientists who are joining forces to re-invent modern architecture Design, Construction project managment and We increase the efficiency of construction projects through modern, user-friendly solutions.</p>
            </div>
          </div>

          <div className='about-card right-image'>
            <div>
              <span>— WHAT WE DO</span>
              <h3>The future of
                Renovative Solutions</h3>
              <p>As the clients’ single point of contact, STIKCON integrates the activities of all contractors and subcontractors, to ensure the success of the overall program. STIKCON focus is on Strategic Program Management – executive involvement, project excellence and teamwork are a key factor in the projects’ success.
              </p>
            </div>
            <img src={require("../assets/images/d9.png")} alt='About-us' className='about-img' />

          </div>

          <div className='about-card'>
            <img src={require("../assets/images/d10.png")} alt='About-us' className='about-img left-image' />
            <div>
              <span>— WHAT WE DO</span>
              <h3>Creativity and
                Unique Perspective</h3>
              <p>We have the capability to provide the Engineering & management, logistics, equipment supply, site preparation, equipment installation and full site works to your needs, all fully under strict quality control, on time.</p>        </div>
          </div>

        </section>
      </div>
    </Layout>
  )
}

export default AboutUs