import React, { useEffect, useState } from 'react';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { getTestimonialDetails } from '../Redux/Home/action';





const Testimonials = () => {
	const [testimonails, setTestimonials] = useState([])
	useEffect(() => {
		getTestimonialDetails()((response) => {
			if (response && response.status) {
				setTestimonials(response?.data?.results)
			}
		});
	}, []);
	const testimonialInfo = testimonails.map(c => {
		return (
			<div key={c.id}>
				<div className='testimonial-info'>
					<img src={c.image} alt={c.name} style={{ objectFit: 'cover' }} />
					<div>
						<h3>{c.name}</h3>
						<p>{c.description}</p>
					</div>
				</div>
			</div>
		)
	})

	var settings = {
		dots: false,
		infinite: true,
		speed: 500,
		autoplay: true,
		autoplaySpeed: 5000,
		slidesToShow: 2,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	};

	return (
		<section className='testimonials'>
			<div className='site-wrapper index-section'>
				<div className='section-top' style={{ marginBottom: "75px" }}>
					<span>— TESTIMONIAL</span>
					<h3 className='testimonail-title'>Take a look at our client’s happiness and recommendations.</h3>
				</div>
				<Slider {...settings}>
					{testimonialInfo}
				</Slider>
			</div>
		</section>
	)
}

export default Testimonials;