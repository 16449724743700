import React, { useState } from "react";
import Signup from "./signup";
import Otp from "./otp";
import Login from "./login";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { useNavigate } from "react-router-dom";
import { customerLogin, customerLoginVerifyOtp, customerRegister, customerVerifyOtp } from "../Redux/Auth/action";
import { toast, ToastContainer } from "react-toastify";


const LoginAndSignup = (props) => {

  const [activeView, setActiveView] = useState("Login")
  const [otpTo, setOtpTo] = useState()
  const [verifyFrom, setVerifyFrom] = useState('');
  const { handleClose, setUpdate, } = props;
  const [otp, setOtp] = useState('')
  const [formValues, setFormValues] = useState({})
  const [loading, setLoading] = useState(false)
  const { navigate } = useNavigate();
  const validationSchema = Yup.object().shape({
    // name: Yup.string()
    //     .required('Required'),

    // rate: Yup.number()
    //     .required('Required'),
    // limit: Yup.number()
    //     .required('Required'),

  });
  const registerFormik = useFormik({
    initialValues: {
      name: "",
      last_name: "",
      email: "",
      phone: "",
      location: "",
      web_code: "",
      capcha: "",

    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required('Required'),
      last_name: Yup.string()
        .required('Required'),
      email: Yup.string()
        .required('Required').email(),

      phone: Yup.number()
        .required('Required'),
      location: Yup.string()
        .required('Required'),
      web_code: Yup.string()
        .required('Required').max(2),
      capcha: Yup.string()
        .required('Required'),

    }),
    onSubmit: (values) => {
      setLoading(true)
      let formData = new FormData();
      formData.append('name', values.name);
      formData.append('last_name', values.last_name);
      formData.append('phone', values.phone);
      formData.append('email', values.email);
      formData.append('device', 'mobile');
      formData.append('country_web_code', values.web_code);
      formData.append('location', values.location);
      customerRegister({ formData })((response) => {
        if (response && response.status) {
          console.log(response.data);
          setActiveView('OTP')
          setVerifyFrom('register')
          setOtpTo(values.email + 'and' + values.phone)
          setLoading(false)
          toast.success("Otp sent successfully", {
            position: toast.POSITION.TOP_RIGHT
          });
        }
        else {
          setLoading(false)
          toast.error(response?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      });

    },
  });
  const loginFormik = useFormik({
    initialValues: {
      username: "",

    },
    validate: values => {
      let errors = {};
      var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      var phoneRegex = /^[0-9]{10}$/;

      if (!emailRegex.test(values?.username) && !phoneRegex.test(values?.username)) {
        errors.username = 'Invalid username';
      }


      return errors;
    },
    onSubmit: (values) => {
      setLoading(true)
      let formData = {};
      var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      var phoneRegex = /^[0-9]{10}$/;

      if (emailRegex.test(values?.username)) {

        formData.email = values?.username;

      } else if (phoneRegex.test(values?.username)) {

        formData.phone = values?.username;
        formData.country_web_code = 'IN';

      } else {

        toast.error('Invalid Email / Phone number', {
          position: toast.POSITION.TOP_RIGHT
        });

      }

      setFormValues(formData)
      customerLogin({ formData })((response) => {
        if (response && response.status) {
          setLoading(false)
          setOtpTo(values?.username)
          setActiveView('OTP')
          setVerifyFrom('login')
          toast.success("Otp sent successfully", {
            position: toast.POSITION.TOP_RIGHT
          });
        }
        else {
          setLoading(false);
          toast.error(response?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      });

    },
  });
  const onVerifyOtp = (otp) => {
    let formData = new FormData();
    if (verifyFrom == 'login') {

      var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      var phoneRegex = /^[0-9]{10}$/;

      if (emailRegex.test(loginFormik.values?.username)) {

        formData.append('email', loginFormik.values.username);
        formData.append('otp', otp);

      } else if (phoneRegex.test(loginFormik.values?.username)) {

        formData.append('phone', loginFormik.values?.username);
        formData.append('country_web_code', 'IN');
        formData.append('otp', otp);

      } else {

        toast.error('Invalid Email / Phone number', {
          position: toast.POSITION.TOP_RIGHT
        });
        return
      }
      customerLoginVerifyOtp({ formData })((response) => {
        if (response && response.status) {
          localStorage.setItem('token', response?.data?.token)
          localStorage.setItem('refresh_token', response?.data?.refresh)
          localStorage.setItem('user', JSON.stringify(response?.data?.user))
          localStorage.setItem('is_logged', 'logged')

          handleClose()
          setOtp('')
          toast.success("Logged in successfully", {
            position: toast.POSITION.TOP_RIGHT
          });
        }
        else {
          toast.error(response?.response?.data?.message ? response?.response?.data?.message : 'Something went wrong', {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      });
    }
    else {
      formData.append('phone', registerFormik.values.phone);
      formData.append('email', registerFormik.values.email);
      formData.append('country_web_code', registerFormik.values.web_code);
      formData.append('otp', otp);
      customerVerifyOtp({ formData })((response) => {
        if (response && response.status) {
          setActiveView('Login')
          setOtp('')
          toast.success("Registered successfully", {
            position: toast.POSITION.TOP_RIGHT
          });
        }
        else {
          toast.error(response?.response?.data?.message ? response?.response?.data?.message : 'Something went wrong', {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      });
    }

  }

  const resendOtp = (formData, username) => {
    customerLogin({ formData })((response) => {
      if (response && response.status) {
        setLoading(false)
        setOtpTo(username)
        setActiveView('OTP')
        setVerifyFrom('login')
        toast.success("OTP sent successfully", {
          position: toast.POSITION.TOP_RIGHT
        });
      }
      else {
        setLoading(false);
        toast.error(response?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    });

  };

  return (
    <div className="popup-box">
      {/* <ToastContainer style={{ zIndex: 99999 }} /> */}
      {/* ----- Starts Sign up  ------ */}
      {activeView === "Signup" && (
        <Signup activeView={setActiveView} handleClose={handleClose} formik={registerFormik} />
      )}
      {/* ----- Ends Sign up  ------ */}


      {/* ----- Starts Login  ------ */}
      {activeView === "Login" && (
        <Login activeView={setActiveView} handleClose={handleClose} formik={loginFormik} loading={loading} />
      )}
      {/* ----- Ends Login  ------ */}


      {/* ----- Starts OTP  ------ */}
      {activeView === "OTP" && (
        <Otp handleClose={handleClose} otp={otp} setOtp={setOtp} onVerifyOtp={onVerifyOtp} otpTo={otpTo} resendOtp={resendOtp} formValues={formValues} />
      )}
      {/* ----- Ends OTP  ------ */}

    </div>
  );
};

export default LoginAndSignup;