import React, { useEffect, useState } from "react";
import "../../node_modules/slick-carousel/slick/slick.css";
import "../../node_modules/slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Layout from "../components/layout";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowLeft,
    faDownload,
    faHeart,
    faShareAlt,
} from "@fortawesome/free-solid-svg-icons";
import ProductDetailsTable from "../components/product/product-details-table";
import { faLongArrowRight } from "@fortawesome/free-solid-svg-icons";
import ImageSlider from "../components/image-slider";
import {
    createConsultation,
    getAllProductDetails,
    getProductDetails,
} from "../components/Redux/Product/action";
import {
    removeWishListCustomerDesign,
    wishListCustomerDesign,
} from "../components/Redux/Design/action";
import { Form, Modal, Tab, Tabs } from "react-bootstrap";
import { saveAs } from "file-saver";
import { RWebShare } from "react-web-share";
import * as Yup from "yup";
import { useFormik } from "formik";
import { listCategoryRooms } from "../components/Redux/Home/action";
import { toast, ToastContainer } from "react-toastify";
import { BASE_URL } from "../components/api";

const ProductDetailsPage = () => {
    const { id } = useParams();
    const [expanded, setExpanded] = useState(false);
    const [productdetails, setProductdetails] = useState([]);
    const [products, setProducts] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [showLogin, setShowLogin] = useState(false);
    const [toaster, setToaster] = useState("");
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [show, setShow] = useState(false);
    const [show3D, setShow3D] = useState(false);
    const [is3DLoader, setIs3DLoader] = useState(false);
    const [uid, setUid] = useState("")
    const logged = localStorage.getItem("is_logged");

    const navigate = useNavigate();

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));
        getProductDetails(
            id,
            user?.id
        )((response) => {
            if (response && response.status) {
                setProductdetails(response?.data?.results[0]);
                if (response?.data?.results[0]?.favorite == true) {
                    setExpanded(true);
                } else {
                    setExpanded(false);
                }
                // navigate('/select-design')
            }
        });
        getAllProductDetails(
            page,
            limit
        )((response) => {
            if (response && response.status) {
                setProducts(response?.data?.results);

                // navigate('/select-design')
            }
        });
        listCategoryRooms()((response) => {
            if (response && response.status) {
                setRooms(response?.data?.results);
            }
        });
    }, []);

    const handleSketchFab = (id) => {

      const loadScript = (src, callback) => {
          const script = document.createElement("script");
          script.type = "text/javascript";
          script.src = src;
          script.onload = () => {
            callback();
          };
          document.head.appendChild(script);
      };

      // Callback function to initialize the Sketchfab Viewer
      const initViewer = () => {

          const iframe = document.getElementById("api-frame");
          const client = new window.Sketchfab(iframe);

          client.init(id, {
              success: function onSuccess(api) {
                api.start(() => setIs3DLoader(false));
                api.addEventListener("viewerready", function () {
                  // setIs3DLoader(false);
                  });
              },
              error: function onError() {
                // setIs3DLoader(false);
                console.log("Viewer error");
              },
          });
      };

      // Load the Sketchfab Viewer API script and initialize the viewer
      loadScript(
          "https://static.sketchfab.com/api/sketchfab-viewer-1.12.1.js",
          initViewer
      );
    }

    useEffect(() => {
        // handleSketchFab(uid);
        // handleSketchFab();
    }, []);

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            phone: "",
            room: "",
            message: "",
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required("Required"),
            email: Yup.string().email().required("Required"),
            phone: Yup.string().required("Required"),
            // room: Yup.string()
            //   .required('Required'),
            message: Yup.string().required("Required"),
        }),
        onSubmit: (values) => {
            console.log(values);
            let formData = new FormData();
            formData.append("name", values.name);
            formData.append("email", values.email);
            formData.append("phone_number", values.phone);
            formData.append("message", values.message);
            formData.append("room_type", values.room);
            createConsultation({ formData })((response) => {
                if (response && response.status) {
                    formik.handleReset();
                    setShow(false);
                    toast.success("Message sent successfully", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else {
                    toast.error(
                        response?.response?.data?.message
                            ? response?.response?.data?.message
                            : "Something went wrong",
                        {
                            position: toast.POSITION.TOP_RIGHT,
                        }
                    );
                }
            });
        },
    });

    // Similar Properties

    const settings = {
        dots: false,
        infinite: false,
        arrows: false,
        rows: 1,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        centerMode: false,
    };

    // Tags
    const tagsArr = [
        { id: 1, title: "simplex house design", link: "#" },
        { id: 2, title: "House Design 3d", link: "#" },
        { id: 3, title: "Duplex house floor plan designs", link: "#" },
        { id: 4, title: "3d house elevation designs", link: "#" },
        { id: 5, title: "Floor planner for duplex house", link: "#" },
        { id: 6, title: "3d house elevation designs", link: "#" },
        { id: 7, title: "Duplex house floor plan designs", link: "#" },
        { id: 8, title: "Floor planner for duplex house", link: "#" },
        { id: 9, title: "simplex house design", link: "#" },
        { id: 10, title: "House Design 3d", link: "#" },
    ];

    const tags = tagsArr.map((tag) => {
        return (
            <Link key={tag.id} to={tag.link}>
                {tag.title}
            </Link>
        );
    });

    const optionIcons = (
        <div className="details-info-head-icons">
            <button>
                {expanded ? (
                    <FontAwesomeIcon
                        icon={faHeart}
                        onClick={() => removeFromWhishlist()}
                        style={{ color: "#DF300A" }}
                    />
                ) : (
                    <FontAwesomeIcon
                        onClick={() => addToWishList()}
                        icon={faHeart}
                        style={{ color: "#0C0B0B" }}
                    />
                )}
            </button>
            <RWebShare
                data={{
                    text: productdetails?.title,
                    url:
                        BASE_URL +
                        "/product-details-page/" +
                        productdetails?.id,
                    title: productdetails?.title,
                }}
                onClick={() => console.log("shared successfully!")}
            >
                <button>
                    {" "}
                    <FontAwesomeIcon icon={faShareAlt} />
                </button>
            </RWebShare>
        </div>
    );
    const addToWishList = () => {
        if (logged == "logged") {
            let formData = new FormData();
            formData.append("home_plan", id);
            wishListCustomerDesign({ formData })((response) => {
                if (response && response.status) {
                    setExpanded(true);
                    toast.success("Item added to wishlist", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else {
                    toast.error(
                        response?.response?.data?.message
                            ? response?.response?.data?.message
                            : "Something went wrong",
                        {
                            position: toast.POSITION.TOP_RIGHT,
                        }
                    );
                }
            });
        } else {
            toast.warning("Please login first", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };
    const removeFromWhishlist = () => {
        removeWishListCustomerDesign(productdetails?.id)((response) => {
            if (response && response.status) {
                setExpanded(false);
                toast.success("Item removed from wishlist", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            } else {
                toast.error(
                    response?.response?.data?.message
                        ? response?.response?.data?.message
                        : "Something went wrong",
                    {
                        position: toast.POSITION.TOP_RIGHT,
                    }
                );
            }
        });
    };
    const downloadImage = (file, name) => {
        if (productdetails?.purchased_status) {
            // saveAs(file) // Put your image url here.
            file = file.replace("http://", "https://");
            console.log(file);
            fetch(file).then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement("a");
                    a.href = url;
                    a.download = name;
                    a.click();
                });
            });
        } else {
            toast.warning("You need to buy this first.", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };
    return (
        <Layout showLogin={showLogin} setShowLogin={setShowLogin}>
            <ToastContainer style={{ zIndex: 99999 }} />

            <div className="product-details-page">
                <section className="site-wrapper">
                    <div className="back-btn">
                        <Link to={"/product-page"}>
                            <FontAwesomeIcon icon={faArrowLeft} />
                            Back
                        </Link>
                    </div>

                    <div className="details-page-hero">
                        <ImageSlider imgs={productdetails?.image} />
                        <div className="details-info details-info-orders">
                            <div className="details-info-head">
                                <h2>{productdetails?.title}</h2>
                                <div className="option-icons-show">
                                    {optionIcons}
                                </div>
                            </div>
                            <Tabs
                                defaultActiveKey="projectDetails"
                                transition={false}
                                mountOnEnter={false}
                                className="designs-details-tabs"
                            >
                                <Tab
                                    eventKey="projectDetails"
                                    title="Project Details"
                                >
                                    <div className="details-infos-lastorder">
                                        <h3>Project Details</h3>
                                        <p
                                            style={{
                                                color: "var(--muted)",
                                                marginTop: "16px",
                                            }}
                                        >
                                            {productdetails?.description}
                                        </p>
                                        <div className="details-info-options">
                                            <span>
                                                <img
                                                    src={require("../assets/images/di1.png")}
                                                    alt="icon"
                                                />
                                                Dimension :{" "}
                                                {productdetails?.dimensions}{" "}
                                                Sqft{" "}
                                            </span>
                                            {/* <span><img src={require("../assets/images/di2.png")} alt='icon' />Plot Area : 2100 Sqft</span> */}
                                            {productdetails?.location == "" ? (
                                                <></>
                                            ) : (
                                                <span>
                                                    <img
                                                        src={require("../assets/images/di3.png")}
                                                        alt="icon"
                                                    />
                                                    Location :{" "}
                                                    {productdetails?.location}
                                                </span>
                                            )}
                                            {/* <span><img src={require("../assets/images/di4.png")} alt='icon' />Duplex Floor Plan </span> */}
                                        </div>
                                    </div>
                                </Tab>
                                {/* {productdetails?.purchased_status && */}
                                <Tab
                                    eventKey="downloadDesign"
                                    title="Download Design"
                                >
                                    <div className="download-design">
                                        <h2>Files</h2>
                                        {productdetails?.dynamic &&
                                            productdetails?.dynamic?.map(
                                                (file) =>
                                                    file?.files != "" && (
                                                        <div className="download-desc mt-2">
                                                            <span>
                                                                {file?.name}
                                                            </span>
                                                            <button
                                                                onClick={() =>
                                                                    downloadImage(
                                                                        file?.files,
                                                                        productdetails?.title +
                                                                            file?.name
                                                                    )
                                                                }
                                                                className="button"
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faDownload
                                                                    }
                                                                />{" "}
                                                                Download
                                                            </button>
                                                        </div>
                                                    )
                                            )}
                                    </div>
                                </Tab>
                                {/* } */}
                            </Tabs>

                            <div className="details-infos-price">
                                <div className="option-hide-head">
                                    <div className="option-hide-head-flex">
                                        <ul>
                                            <li className="price-title">
                                                Price:
                                            </li>
                                            <li className="price-value">
                                                ₹ {productdetails?.total}
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="option-icons-hide">
                                        {optionIcons}
                                    </div>
                                </div>
                                {logged == "logged" ? (
                                    <>
                                        <div className="button-main-container">
                                            {/* <button
                                                className="button price-button"
                                                style={{ margin: "0px" }}
                                            > */}
                                                {productdetails?.purchased_status ? (
                                                    <button
                                                        className="button price-button"
                                                        style={{ margin: "0px" }}
                                                        onClick={() =>
                                                            setShow(true)
                                                        }
                                                    >
                                                        <div
                                                            className="small-font"
                                                        >
                                                            Need a custom design
                                                        </div>
                                                    </button>
                                                ) : (
                                                    <button
                                                        className="button price-button"
                                                        style={{ margin: "0px" }}
                                                        onClick={() => navigate(`/order-summery/${productdetails?.id}`)}
                                                    >
                                                        <div
                                                            // to={
                                                            //     "/order-summery/" +
                                                            //     productdetails?.id
                                                            // }
                                                            className="small-font"
                                                        >
                                                            Buy Now
                                                        </div>
                                                    </button>
                                                )}
                                            {/* </button> */}
                                            {
                                                productdetails?.purchased_status ?
                                                    productdetails?.sketch_fab_uid &&
                                                    <button
                                                        className="button price-button"
                                                        style={{ margin: "0px" }}
                                                        onClick={() => {
                                                        setShow3D(true);
                                                        setIs3DLoader(true);
                                                        handleSketchFab(productdetails?.sketch_fab_uid);
                                                        }}
                                                    >
                                                        <div 
                                                            className="small-font"
                                                        >View in 3D</div>
                                                    </button>
                                                    :
                                                    <button
                                                        className="button price-button"
                                                        style={{ margin: "0px" }}
                                                        onClick={() => {
                                                        setShow3D(true);
                                                        setIs3DLoader(true);
                                                        // setUid("4ac82a3b682d493fbc57b37be8f46086")
                                                        handleSketchFab("05df52b72e1949afa350cd42198eccc5");
                                                        }}
                                                    >
                                                        <div
                                                            className="small-font"
                                                        >3D Demo</div>
                                                    </button>
                                            }
                                        </div>
                                        {
                                            !productdetails?.purchased_status &&
                                            <p style={{ margin: "0", fontSize: "14px", fontStyle: "italic"}}>Buy now and gain access to a detailed 3D visualization of the design.</p>
                                        }
                                    </>
                                ) : (
                                    <button
                                        className="button price-button"
                                        onClick={() => setShowLogin(true)}
                                        style={{ margin: "0px" }}
                                    >
                                        <div>Login</div>
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </section>

                <ProductDetailsTable productslist={productdetails} />
                <section>
                    <div className="site-wrapper similar-properties-heading">
                        <h3>Similar Properties</h3>
                        <div className="similar-properties-exploreAll">
                            <Link to={"/product-page"}>
                                Explore All{" "}
                                <FontAwesomeIcon icon={faLongArrowRight} />
                            </Link>
                        </div>
                    </div>
                    <div className="site-wrapper similar-properties">
                        <div className="similar-properties-slide">
                            <Slider {...settings}>
                                {products?.map((similar_product, Index) => (
                                    <div
                                        className="house-design-card"
                                        key={Index}
                                    >
                                        <img
                                            src={
                                                similar_product.image &&
                                                similar_product.image[0] &&
                                                similar_product.image[0].image
                                            }
                                            alt={similar_product.title}
                                        />
                                        <div className="design-card-info">
                                            <h3>{similar_product.title}</h3>
                                            <div className="design-card-options"></div>
                                            <button className="button">
                                                <Link
                                                    to={
                                                        "/product-details-page/" +
                                                        similar_product?.id
                                                    }
                                                >
                                                    View Details
                                                </Link>
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </section>
                <section className="site-wrapper">
                    <h3>People also search</h3>
                    <div className="details-page-tags">{tags}</div>
                </section>
            </div>

            {/* Modal for Talk to Designer for custom design */}
            <Modal
                size="lg"
                show={show}
                onHide={() => setShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                className="consultation"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Talk to a Designer
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-lg-6">
                                <Form.Group controlId="" className="input-text">
                                    <Form.Control
                                        name="name"
                                        className={
                                            formik.errors.name && "error-input"
                                        }
                                        isValid={
                                            formik.touched.name &&
                                            !formik.errors.name
                                        }
                                        isInvalid={!!formik.errors.name}
                                        value={formik.values?.name}
                                        onChange={formik.handleChange}
                                        type="text"
                                        placeholder="Name"
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-lg-6">
                                <Form.Group controlId="" className="input-text">
                                    <Form.Control
                                        name="email"
                                        className={
                                            formik.errors.email && "error-input"
                                        }
                                        isValid={
                                            formik.touched.email &&
                                            !formik.errors.email
                                        }
                                        isInvalid={!!formik.errors.email}
                                        value={formik.values?.email}
                                        onChange={formik.handleChange}
                                        type="email"
                                        placeholder="Email"
                                    />
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row pt-4">
                            <div className="col-lg-6">
                                <Form.Group controlId="" className="input-text">
                                    <Form.Control
                                        name="phone"
                                        className={
                                            formik.errors.phone && "error-input"
                                        }
                                        isValid={
                                            formik.touched.phone &&
                                            !formik.errors.phone
                                        }
                                        isInvalid={!!formik.errors.phone}
                                        value={formik.values?.phone}
                                        onChange={formik.handleChange}
                                        type="text"
                                        placeholder="Phone"
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-lg-6">
                                <Form.Select
                                    className="mobile-num-radius"
                                    name="room"
                                    value={formik.values.room}
                                    onChange={formik.handleChange}
                                    isInvalid={!!formik.errors.room}
                                >
                                    <option value="" disabled={true}>
                                        Select Room
                                    </option>
                                    {rooms?.map((room) => (
                                        <option value={room?.id}>
                                            {room?.room_title}
                                        </option>
                                    ))}
                                </Form.Select>
                            </div>
                        </div>
                        <div className="row pt-4">
                            <div className="col-lg-12 ">
                                <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlTextarea1"
                                >
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        name="message"
                                        onChange={formik.handleChange}
                                        isInvalid={!!formik.errors.message}
                                        placeholder="Type description..."
                                    />
                                </Form.Group>
                            </div>
                        </div>
                        <button
                            className="button consultation-button"
                            type="submit"
                        >
                            <div>Get Consultation</div>
                        </button>
                        <div className="register-box-info d-flex mt-5">
                            <Form.Check label="" name="group1" />
                            <p className="sign-up-text">
                                I agree to the<span> Terms & Conditions</span>
                            </p>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Modal for 3D view */}
            <Modal
                size="lg"
                show={show3D}
                onHide={() => setShow3D(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                className="view-3d"
            >
                {/* {
                  is3DLoader ? 
                  <div style={{ color: '#fff', fontSize: "100px"}}>Loader...</div>
                  : 
                  null
                } */}
                <iframe
                    // src=""
                    id="api-frame"
                    allow="autoplay; fullscreen; xr-spatial-tracking"
                    height={"500px"}
                    width="100%"
                    xr-spatial-tracking
                    execution-while-out-of-viewport
                    execution-while-not-rendered
                    web-share
                    allowfullscreen
                    mozallowfullscreen="true"
                    webkitallowfullscreen="true"
                ></iframe>
            </Modal>
        </Layout>
    );
};

export default ProductDetailsPage;
