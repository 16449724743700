import React, { useState } from 'react'
import SliderRange from './slider-range'

const PageOptions = (props) => {
  const [active, setActive] = useState(false)

  const { formik, clearFormData } = props;
  const handleClick = (event) => {
    setActive(event.target.id);
    formik.setValues({
      ...formik.values,
      bed_room: event.target.id
    })
  }

  const [active2, setActive2] = useState(false)
  const handleClick2 = (event) => {
    setActive2(event.target.id);
    formik.setValues({
      ...formik.values,
      bath_room: event.target.id
    })
  }

  const [active3, setActive3] = useState(false)
  const handleClick3 = (event) => {
    setActive3(event.target.id);
    formik.setValues({
      ...formik.values,
      balcony: event.target.id
    })
  }
  const resetForm = () => {
    formik.setValues({
      ...formik.values,
      bed_room: '',
      bath_room: '',
      balcony: '',
      car_parking: '',
      pooja_room: '',
      drawing_area: '',
      from_price: '',
      to_price: '',
    })
  }
  return (
    <div className='product-page-options'>
      <h4>More Filters</h4>
      <div className='option-box'>
        <h5>Bed rooms</h5>
        <div className="selects-info">
          <button
            key={1}
            className={formik.values?.bed_room === "1" ? "select-info-btn-active" : "select-info-btn"}
            id={"1"}
            onClick={handleClick}
          >1</button>
          <button
            key={2}
            className={formik.values?.bed_room === "2" ? "select-info-btn-active" : "select-info-btn"}
            id={"2"}
            onClick={handleClick}
          >2</button>
          <button
            key={3}
            className={formik.values?.bed_room === "3" ? "select-info-btn-active" : "select-info-btn"}
            id={"3"}
            onClick={handleClick}
          >3</button>
          <button
            key={4}
            className={formik.values?.bed_room === "4" ? "select-info-btn-active" : "select-info-btn"}
            id={"4"}
            onClick={handleClick}
          >4</button>
          <button
            key={5}
            className={formik.values?.bed_room === "5" ? "select-info-btn-active" : "select-info-btn"}
            id={"5"}
            onClick={handleClick}
          >5+</button>
        </div>
      </div>

      <div className='option-box'>
        <h5>Bath rooms</h5>
        <div className="selects-info">
          <button
            key={1}
            className={formik.values?.bath_room === "1" ? "select-info-btn-active" : "select-info-btn"}
            id={"1"}
            onClick={handleClick2}
          >1</button>
          <button
            key={2}
            className={formik.values?.bath_room === "2" ? "select-info-btn-active" : "select-info-btn"}
            id={"2"}
            onClick={handleClick2}
          >2</button>
          <button
            key={3}
            className={formik.values?.bath_room === "3" ? "select-info-btn-active" : "select-info-btn"}
            id={"3"}
            onClick={handleClick2}
          >3</button>
          <button
            key={4}
            className={formik.values?.bath_room === "4" ? "select-info-btn-active" : "select-info-btn"}
            id={"4"}
            onClick={handleClick2}
          >4</button>
          <button
            key={5}
            className={formik.values?.bath_room === "5" ? "select-info-btn-active" : "select-info-btn"}
            id={"5"}
            onClick={handleClick2}
          >5+</button>
        </div>
      </div>

      <div className='option-box'>
        <h5>Balcony</h5>
        <div className="selects-info">
          <button
            key={1}
            className={formik.values?.balcony === "1" ? "select-info-btn-active" : "select-info-btn"}
            id={"1"}
            onClick={handleClick3}
          >1</button>
          <button
            key={2}
            className={formik.values?.balcony === "2" ? "select-info-btn-active" : "select-info-btn"}
            id={"2"}
            onClick={handleClick3}
          >2</button>
          <button
            key={3}
            className={formik.values?.balcony === "3" ? "select-info-btn-active" : "select-info-btn"}
            id={"3"}
            onClick={handleClick3}
          >3</button>
          <button
            key={4}
            className={formik.values?.balcony === "4" ? "select-info-btn-active" : "select-info-btn"}
            id={"4"}
            onClick={handleClick3}
          >4</button>
          <button
            key={5}
            className={formik.values?.balcony === "5" ? "select-info-btn-active" : "select-info-btn"}
            id={"5"}
            onClick={handleClick3}
          >5+</button>
        </div>
      </div>
      <div className='option-box'>
        <h5>Price range</h5>
        <SliderRange formik={formik} />
      </div>

      <div className='option-box'>
        <h5>Car parking</h5>
        <div className='checkbox' style={{ gap: "20px" }}>
          <div className='checkbox'>
            <div className="sb-checkbox">
              <input type="checkbox" className="sb-checkbox__input" id="check1" name="check1" checked={formik.values?.car_parking == 1 ? true : false} onClick={() => { formik.setValues({ ...formik.values, car_parking: '1' }) }} />
              <label className="sb-checkbox__label sb-checkbox__label--orange" htmlFor="check1"></label>
            </div>
            <span>Yes</span>
          </div>
          <div className='checkbox'>
            <div className="sb-checkbox">
              <input type="checkbox" className="sb-checkbox__input" id="check2" name="check2" checked={formik.values?.car_parking == 2 ? true : false} onClick={() => { formik.setValues({ ...formik.values, car_parking: '2' }) }} />
              <label className="sb-checkbox__label sb-checkbox__label--orange" htmlFor="check2"></label>
            </div>
            <span>No</span>
          </div>
        </div>
      </div>

      <div className='option-box'>
        <h5>Prayer room</h5>
        <div className='checkbox' style={{ gap: "20px" }}>
          <div className='checkbox'>
            <div className="sb-checkbox">
              <input type="checkbox" className="sb-checkbox__input" id="check3" name="check3" checked={formik.values?.pooja_room == 1 ? true : false} onClick={() => { formik.setValues({ ...formik.values, pooja_room: '1' }) }} />
              <label className="sb-checkbox__label sb-checkbox__label--orange" htmlFor="check3"></label>
            </div>
            <span>Yes</span>
          </div>
          <div className='checkbox'>
            <div className="sb-checkbox">
              <input type="checkbox" className="sb-checkbox__input" id="check4" name="check4" checked={formik.values?.pooja_room == 2 ? true : false} onClick={() => { formik.setValues({ ...formik.values, pooja_room: '2' }) }} />
              <label className="sb-checkbox__label sb-checkbox__label--orange" htmlFor="check4"></label>
            </div>
            <span>No</span>
          </div>
        </div>
      </div>


      <div className='option-box'>
        <h5>Living area</h5>
        <div className='checkbox' style={{ gap: "20px" }}>
          <div className='checkbox'>
            <div className="sb-checkbox">
              <input type="checkbox" className="sb-checkbox__input" id="check5" name="check5" checked={formik.values?.drawing_area == 1 ? true : false} onClick={() => { formik.setValues({ ...formik.values, drawing_area: '1' }) }} />
              <label className="sb-checkbox__label sb-checkbox__label--orange" htmlFor="check5"></label>
            </div>
            <span>Yes</span>
          </div>
          <div className='checkbox'>
            <div className="sb-checkbox">
              <input type="checkbox" className="sb-checkbox__input" id="check6" name="check6" checked={formik.values?.drawing_area == 2 ? true : false} onClick={() => { formik.setValues({ ...formik.values, drawing_area: '2' }) }} />
              <label className="sb-checkbox__label sb-checkbox__label--orange" htmlFor="check6"></label>
            </div>
            <span>No</span>
          </div>
        </div>
      </div>


      <div style={{ display: "flex", gap: "20px", marginTop: "30px" }}>
        <button className='button'
          onClick={clearFormData}
          style={{
            padding: "8px 40px", marginTop: "0px",
            backgroundImage: 'linear-gradient(to right, #E0F0FD, #cdfaef)', color: "var(--primary)"
          }}>
          Reset
        </button>

        <button className='button' style={{ padding: "8px 40px", marginTop: "0px" }} onClick={formik.handleSubmit}>
          Apply
        </button>
      </div>
    </div>
  )
}

export default PageOptions