import React, { Fragment, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Layout from '../components/layout';
import LottieAnimation from '../components/lottie-animation';
import { getCustomerWishList } from '../components/Redux/Design/action';
import Svgloader from '../components/svgloader';
// import ProductCard from '../components/product-card';

const MyFavourites = () => {
  const [favourites, setFavourites] = useState([]);
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate();
  useEffect(() => {
    const is_logged = localStorage.getItem('is_logged');
    if (is_logged != 'logged') {
      navigate('/')
    }
  }, [])

  const myFavourites = favourites.map((favorite, index) => {
    if (favorite?.home_plan && favorite?.home_plan != '') {
      return (
        <div className="product-card" key={index}>
          <img src={favorite?.details[0]?.image && favorite?.details[0]?.image[0]?.image} alt={favorite?.title} />
          <div>
            <h3>{favorite?.details[0].title}</h3>
            <button className='button'><Link to={'/product-details-page/' + favorite?.home_plan}>View Details</Link></button>
          </div>
        </div>
      )
    }
    else if (favorite?.my_design && favorite.my_design != '') {
      <div className="product-card" key={index}>
        <img src={favorite?.image} alt={favorite?.title} />
        <div>
          <h3>{favorite?.title}</h3>
          <button className='button'><Link to={'/'}>View Details</Link></button>
        </div>
      </div>
    }
    else if (favorite?.interior_design && favorite.interior_design != '') {
      <div className="product-card" key={index}>
        <img src={favorite?.image} alt={favorite?.title} />
        <div>
          <h3>{favorite?.title}</h3>
          <button className='button'><Link to={'/'}>View Details</Link></button>
        </div>
      </div>
    }
  })
  useEffect(() => {
    setLoading(true)
    getCustomerWishList()((response) => {
      if (response && response.status) {
        setFavourites(response?.data?.results)
        setLoading(false)
      }
      else {
        setLoading(false)
      }
    });
  }, [])

  return (
    <Layout>
      <div className='site-wrapper min-height-400'>
        <h3 className='my-designs-title'>My Favourites</h3>
        {loading ?
          <div className='m-5'>
            <Svgloader />
          </div>
          :
          <Fragment>
            {favourites?.length == 0 ?
              <Fragment>
                <LottieAnimation text='No data' />
              </Fragment>
              :
              <div className='my-designs-cards'>
                {myFavourites}
              </div>
            }
          </Fragment>
        }
      </div>
    </Layout>
  )
}

export default MyFavourites