import React, { Fragment, useEffect, useState } from 'react'
import Layout from '../components/layout';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { Form, InputGroup } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom'
import { Dropdown } from 'react-bootstrap';
import PageOptions from '../components/product/page-options';
import ProductForm from '../components/product/product-form';
import { getAllProductDetails } from '../components/Redux/Product/action';
import { useFormik } from 'formik';
import Svgloader from '../components/svgloader';
import LottieAnimation from '../components/lottie-animation';


const ProductPage = () => {

  const [cardView, setCardView] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const filters = location?.state
  useEffect(() => {
    setLoading(true)
    if (filters && filters != null) {
      console.log(filters?.pooja_room)
      getAllProductDetails(page, limit, filters?.search, filters?.direction, filters?.property_type, '', '', '', '', '', filters?.bed_room, filters?.bath_room, filters?.balcony, filters?.car_parking, filters?.pooja_room, filters?.drawing_area, filters?.from_price, filters?.to_price)((response) => {
        if (response && response.status) {
          if (page > 1) {
            setProducts(products.concat(response?.data?.results))
          }
          else {
            setProducts(response?.data?.results)
          }
          setCount(response?.data?.count)
          setLoading(false)
        }
        else {
          setLoading(false)

        }
      });
      formik.setValues({
        ...formik.values,
        direction: filters?.direction ? filters?.direction : '',
        search: filters?.search ? filters?.search : '',
        property_type: filters?.property_type ? filters?.property_type : '',
        bed_room: filters?.bed_room ? filters?.bed_room : '',
        bath_room: filters?.bath_room ? filters?.bath_room : '',
        balcony: filters?.balcony ? filters?.balcony : '',
        car_parking: filters?.car_parking ? filters?.car_parking : '',
        pooja_room: filters?.pooja_room ? filters?.pooja_room : '',
        drawing_area: filters?.drawing_area ? filters?.drawing_area : '',
        from_price: filters?.from_price ? filters?.from_price : '',
        to_price: filters?.to_price ? filters?.to_price : '',
      })
      window.history.replaceState({}, document.title)
    } else {

      getAllProductDetails(page, limit)((response) => {
        if (response && response.status) {
          if (page > 1) {
            setProducts(products.concat(response?.data?.results))
          }
          else {
            setProducts(response?.data?.results)
          }
          setCount(response?.data?.count)
          setLoading(false)
        }
        else {
          setLoading(false)

        }
      });
    }
  }, [page, limit])
  const formik = useFormik({
    initialValues: {
      width: "",
      length: "",
      diagonal_length: '',
      left_length: '',
      back_width: '',
      direction: "",
      search: "",
      property_type: "",
      bed_room: '',
      bath_room: '',
      balcony: '',
      car_parking: '',
      pooja_room: '',
      drawing_area: '',
      from_price: 0,
      to_price: 50000,
    },
    onSubmit: (values) => {
      setLoading(true)
      getAllProductDetails(
        page,
        limit,
        values?.search,
        values?.direction,
        values?.property_type,
        values?.width,
        values?.length,
        values.back_width,
        values.left_length,
        values.diagonal_length,
        values?.bed_room,
        values?.bath_room,
        values?.balcony,
        values?.car_parking,
        values?.pooja_room,
        values?.drawing_area,
        values?.from_price,
        values?.to_price
      )((response) => {
        if (response && response.status) {
          setProducts(response?.data?.results)
          setCount(response?.data?.count)
          setLoading(false)
        }
        else {
          setLoading(false)

        }
      });



    },
  });



  const houses = products.map((product) => {
    const cardInfo = (
      <div className="product-house-info">
        <h3>{product.title}</h3>
        <div className="product-house-options">
          <div><img src={require("../assets/images/di5.png")} alt='icon' /><span>{product?.width}{' M x '}{product?.length}{' M'}</span></div>
          <div><img src={require("../assets/images/di6.png")} alt='icon' /><span>{product?.dimensions + ' Sqft'}</span></div>
          <div><img src={require("../assets/images/di7.png")} alt='icon' /><span>{product?.property_name}</span></div>
        </div>
        <div className='product-house-details'>
          <button className='button' style={{ margin: "0px" }}>
            {/* <Link to={product.link}>View Details</Link> */}
            <Link to={"/product-details-page/" + product?.id}>View Details</Link>
          </button>
          <h5>Price: <span style={{ color: "var(--black)", fontFamily: "sans-serif", fontWeight: "600", marginLeft: "10px" }}>₹ {product?.total}</span></h5>
        </div>
      </div>
    )
    return (
      <React.Fragment key={product.id}>
        {cardView === "listView" ?
          (
            < div className="product-house-list-card">
              <img src={product.image && product.image[0] && product.image[0].image} alt={product?.title} />
              {cardInfo}
            </div>
          ) :
          (
            <div className="product-house-card">
              <img src={product.image && product.image[0] && product.image[0].image} alt={product?.title} />
              {cardInfo}
            </div>
          )
        }
      </React.Fragment>
    )
  })
  const clearFormData = async () => {
    console.log('here');
    await formik.resetForm();
    getAllProductDetails(page, limit)((response) => {
      if (response && response.status) {
        if (page > 1) {
          setProducts(products.concat(response?.data?.results))
        }
        else {
          setProducts(response?.data?.results)
        }
        setCount(response?.data?.count)
        setLoading(false)
      }
      else {
        setLoading(false)

      }
    });

  }
  return (
    <Layout>
      <div className='site-wrapper'>
        <div>
          <ProductForm formik={formik} filters={filters} clearFormData={clearFormData} />
          <div className='head-info-product'>
            <h3>Check and discover our design plans</h3>
            <div className='product-head-icons'>
              <div className='hide-xl'>
                <Dropdown className='product-tabs-select product-filter'>
                  <Dropdown.Toggle>
                    <FontAwesomeIcon icon={faFilter} /> More Filters
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <PageOptions formik={formik} clearFormData={clearFormData} />
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className='view-change-btn'>
                  <button className='icon-btn' onClick={() => setCardView("listView")}>
                    {cardView === "listView" ?
                      (<img src={require("../assets/images/i3.png")} alt='icon' style={{ width: "20px", height: "20px" }} />) :
                      (<img src={require("../assets/images/i1.png")} alt='icon' style={{ width: "20px", height: "20px" }} />)
                    }
                  </button>
                  <button className='icon-btn' onClick={() => setCardView("")}>
                    {cardView === "listView" ?
                      (<img src={require("../assets/images/i4.png")} alt='icon' style={{ width: "20px", height: "20px" }} />) :
                      (<img src={require("../assets/images/i2.png")} alt='icon' style={{ width: "20px", height: "20px" }} />)
                    }
                  </button>
                </div>
                {/* <InputGroup className='product-tabs-select product-filter' style={{ width: "180px", marginLeft: "10px" }}>
                  <Form.Select aria-label="Default select example">
                    <option>Sort by</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </InputGroup> */}
              </div>
            </div>
          </div>
          <div className='product-page-infos'>
            <aside className='hide-sm'><PageOptions formik={formik} clearFormData={clearFormData} /></aside>
            {loading && page == 1 ?
              <Fragment>
                <div>
                  <Svgloader />
                </div>
              </Fragment>
              :
              <Fragment>
                {products?.length == 0 ?
                  <Fragment>
                    <div className='mt-5'>
                      <LottieAnimation text='No data' />
                    </div>
                  </Fragment>
                  :
                  <Fragment>
                    {cardView === "listView" ?
                      (<section className='product-list-houses'>
                        {houses}
                      </section>) :
                      (<section className='product-houses'>
                        {houses}
                      </section>)
                    }
                  </Fragment>
                }
              </Fragment>
            }
          </div>
          {count > products?.length ?
            <Fragment>
              {loading && page > 1 ?
                <Fragment>
                  <Svgloader />
                </Fragment>
                :
                <div style={{ width: "100%", textAlign: "center", marginBottom: "60px" }}>
                  <button className='button' type='button' onClick={() => setPage(page + 1)}>Load More</button>
                </div>
              }
            </Fragment>
            :
            count ?
              <div className='complete-list'>Yay! You have seen it all</div>
              :
              <div></div>
          }
        </div>
      </div>
    </Layout>
  )
}

export default ProductPage