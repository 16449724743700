import React, { useState, Fragment, useEffect } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faFilter, faClose, faHouse } from "@fortawesome/free-solid-svg-icons";
import { Form, InputGroup } from 'react-bootstrap';
import FilterOptions from './filter-options';
import { useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import { listAllDirections, listPropertyTypes } from '../Redux/Home/action';

const TabsSection = () => {

  const [isOpen, setIsOpen] = useState("");
  const [types, setTypes] = useState([])
  const [directions, setDirections] = useState([])
  const togglePopup = () => {
    setIsOpen(!isOpen);
  }

  let navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      direction: "",
      search: "",
      property_type: "",
      bed_room: '',
      bath_room: '',
      balcony: '',
      car_parking: '',
      pooja_room: '',
      drawing_area: '',
      from_price: 0,
      to_price: 15000,

    },
    onSubmit: (values) => {
      navigate('/product-page', {
        state: {
          direction: values.direction,
          search: values.search,
          property_type: values.property_type,
          bed_room: values.bed_room,
          bath_room: values.bath_room,
          balcony: values.balcony,
          car_parking: values.car_parking,
          pooja_room: values.pooja_room,
          drawing_area: values.drawing_area,
          from_price: values.from_price,
          to_price: values.to_price,
        }
      })
    },
  });
  useEffect(() => {
    listPropertyTypes()((response) => {
      if (response && response.status) {
        setTypes(response?.data?.results)
      }
    });
    listAllDirections()((response) => {
      if (response && response.status) {
        setDirections(response?.data)
      }
    });
  }, [])
  return (
    <Fragment>
      <Form onSubmit={formik.handleSubmit}>
        {isOpen === "Filter" ? (<FilterOptions formik={formik} handleChange={togglePopup} />) : (
          <section className='site-wrapper index-section'>
            <div className='tabs-section'>
              <div className='tabs-info'>
                <div>
                  <ul className='tabs-details'>
                    <li style={{ cursor: "pointer" }} onClick={() => setIsOpen("Filter")}>
                      <FontAwesomeIcon icon={faFilter}
                        className="filter-icon"
                      /> More Filters</li>
                    {formik.values.bed_room && formik.values.bed_room != '' &&
                      <li>Bed room: {formik.values.bed_room} <FontAwesomeIcon icon={faClose} /></li>
                    }
                    {formik.values.bath_room && formik.values.bath_room != '' &&
                      <li>Bath room: {formik.values.bath_room} <FontAwesomeIcon icon={faClose} /></li>
                    }
                    {formik.values.balcony && formik.values.balcony != '' &&
                      <li>Balcony: {formik.values.balcony} <FontAwesomeIcon icon={faClose} /></li>
                    }
                    {formik.values.car_parking && formik.values.car_parking != '' &&
                      <li>Car parking: {formik.values.car_parking == 1 ? 'Yes' : 'No'} <FontAwesomeIcon icon={faClose} /></li>
                    }
                    {formik.values.drawing_area && formik.values.drawing_area != '' &&
                      <li>Living area: {formik.values.drawing_area == 1 ? 'Yes' : 'No'} <FontAwesomeIcon icon={faClose} /></li>
                    }
                    {formik.values.pooja_room && formik.values.pooja_room != '' &&
                      <li>Prayer room: {formik.values.pooja_room == 1 ? 'Yes' : 'No'} <FontAwesomeIcon icon={faClose} /></li>
                    }
                  </ul>
                </div>
                <div>
                  <div className='tabs-inputs'>
                    <InputGroup className='tabs-form-input'>
                      <InputGroup.Text >
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="Type your search"
                        aria-label="search"
                        onChange={formik.handleChange}
                        name='search'
                        value={formik.values.search}
                      />
                    </InputGroup>

                    <InputGroup className='tabs-form-select'>
                      <InputGroup.Text >
                        {/* <FontAwesomeIcon icon={faLocationDot} /> */}
                      </InputGroup.Text>
                      <Form.Select aria-label="Default select example" name='direction' onChange={formik.handleChange} value={formik.values?.direction}>
                        <option>Direction</option>
                        {directions?.map(direction =>
                          <option value={direction?.value}>{direction?.label}</option>
                        )}
                      </Form.Select>
                    </InputGroup>

                    <InputGroup className='tabs-form-select'>
                      <InputGroup.Text >
                        <FontAwesomeIcon icon={faHouse} />
                      </InputGroup.Text>
                      <Form.Select aria-label="Default select example"
                        onChange={formik.handleChange}
                        name='property_type'
                        value={formik.values.property_type}
                      >
                        <option>Property Type</option>
                        {types?.map(type =>
                          <option value={type?.id}>{type?.name}</option>
                        )}
                      </Form.Select>
                    </InputGroup>
                    <button
                      type='submit'
                      className='button' style={{ padding: "12px 80px", marginTop: "0px" }}>
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>)
        }
      </Form>
    </Fragment>
  )
}

export default TabsSection