import React, { useEffect, useState } from 'react'
import Layout from '../components/layout'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import Invoice from '../components/invoice';
import { getPaymentListing } from '../components/Redux/Payment/action';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import { useNavigate } from 'react-router-dom';

const Payments = () => {

  const [activeView, setActiveView] = useState("")
  const [payments, setPayments] = useState([])
  const [paymentId, setPaymentId] = useState('')
  const user = JSON.parse(localStorage.getItem('user'))
  const togglePopup = () => {
    setActiveView(!activeView);
  }
  const navigate = useNavigate();
  useEffect(() => {
    const is_logged = localStorage.getItem('is_logged');
    if (is_logged != 'logged') {
      navigate('/')
    }
  }, [])

  useEffect(() => {

    getPaymentListing(user?.id)((response) => {
      if (response && response.status) {
        setPayments(response.data?.results)
      }
    });
  }, [])


  return (
    <Layout>
      {activeView === "Invoice" && (<Invoice handleClick={togglePopup} paymentId={paymentId} />)}
      <div className='site-wrapper'>
        <h4 className='payments-title'>Payments</h4>
        <div style={{ overflowX: "auto" }}>
          <Table>
          <Thead>
            <Tr>
              <Th>Sl. no</Th>
              <Th>Date</Th>
              <Th>Product</Th>
              <Th>Status</Th>
              <Th></Th>
            </Tr>
            </Thead>
            {payments.map((t,index) =>
            <Tbody>
              <Tr key={t.id}>
                <Td>{index+ 1}</Td>
                <Td>{t.date}</Td>
                <Td>{t.title}</Td>
                <Td>{t.payment ? 'Payment Completed' : 'Payment Pending'}</Td>
                <Td className='invoice-btn'>
                  <span onClick={() => {setPaymentId(t.id);setActiveView("Invoice")}}><FontAwesomeIcon icon={faDownload} />Invoice</span>
                </Td>
              </Tr>
              </Tbody>
            )}
          </Table>
          {payments?.length == 0 &&
            <div className="d-flex flex-row align-items-center justify-content-center no-data-text">
              No data
            </div>
          }
        </div>
      </div>
    </Layout>
  )
}

export default Payments