import React from 'react';
import Slider from '@material-ui/core/Slider';
import { Form, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from "@fortawesome/free-solid-svg-icons";

const SliderRange = (props) => {
  // Our States
  const [value, setValue] = React.useState([2000, 5000]);
  const { formik } = props;

  // Changing State when volume increases/decreases
  const rangeSelector = (event, newValue) => {
    setValue(newValue);
    formik.setValues({
      ...formik.values,
      from_price: newValue[0],
      to_price: newValue[1]
    })
    console.log(newValue)
  };

  return (
    <div>
      <div style={{ display: "flex", gap: "20px", marginBottom: "25px", justifyContent: "space-between" }}>
        <InputGroup className='product-tabs-select' style={{ width: "120px", padding: "0px" }}>
          <InputGroup.Text className='remove-input-style' style={{ fontFamily: "sans-serif", fontSize: "20px", fontWeight: "900" }}> ₹ </InputGroup.Text>
          <Form.Control
            placeholder=""
            value={formik.values.from_price}
            aria-describedby="basic-addon2"
            style={{ padding: "8px", color: "var(--black)" }}
          />
        </InputGroup>

        <InputGroup.Text style={{ background: "transparent", border: "none", paddingLeft: "0px", paddingRight: "0px" }}>
          <FontAwesomeIcon icon={faMinus} />
        </InputGroup.Text>

        <InputGroup className='product-tabs-select' style={{ width: "120px", padding: "0px" }}>
          <InputGroup.Text className='remove-input-style' style={{ fontFamily: "sans-serif", fontSize: "20px", fontWeight: "900" }}> ₹ </InputGroup.Text>
          <Form.Control
            placeholder=""
            value={formik.values.to_price}
            aria-describedby="basic-addon2"
            style={{ padding: "8px", color: "var(--black)" }}
          />
        </InputGroup>
      </div>

      <Slider

        onChange={rangeSelector}
        valueLabelDisplay="off"
        min={0}
        max={50000}
        value={[formik.values.from_price, formik.values?.to_price]}
        style={{ color: "rgb(70, 172, 255)" }}
      />
    </div>
  );
}


export default SliderRange