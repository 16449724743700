import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { listPropertyTypes } from '../Redux/Home/action';

const PopularProperty = () => {
  const [types,setTypes] = useState([ ]);
  const navigate = useNavigate();
  useEffect(() => {
    listPropertyTypes()((response) => {
			if (response && response.status) {
				setTypes(response?.data?.results)
			}
		});
  }, [])
  
  
 

  const popularProperty = types.map((parr) => {
    return (
      <div key={parr.id} className="overlay-image" onClick={()=> navigate('/product-page', { state: { property_type: parr.id } })}>
        <h3 className='overlay-image-title'>{parr.name}</h3>
        <img src={parr?.image ? parr?.image : require("../../assets/images/p1.png")} alt={parr.name} className='img-fluid'/>
      </div>
    )
  })
  
  return (
    <section className='site-wrapper index-section'>
      <div className='section-top'>
        <span>— POPULAR</span>
        <h3>Popular Property</h3>
      </div>
      <div className='section-images'>
        {popularProperty}
      </div>
    </section>
  )
}

export default PopularProperty