import React, { useState } from 'react'
import Layout from '../components/layout';
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faDownload, faClose, faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import ProductDetailsTable from '../components/product/product-details-table';
import ImageSlider from '../components/image-slider';
import { Tab } from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs'
import { Form } from 'react-bootstrap';

const MyDesignsDetailsPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [expanded1, setExpanded1] = useState(false)

  const togglePopup = () => {
    setIsOpen(!isOpen);
  }
  const projectDetails = (
    <div>
      <p style={{ color: "var(--muted)", marginTop: "16px" }}>
        Lorem ipsum   consectetur adipiscing elit. Nulla faucibus curabitur
        leo a aliquet arcu, ullamcorper egestas at. Justo, duis tempor,
        libero fermentum ultrices fermentum in.
      </p>
      <div className="details-info-options">
        <span><img src={require("../assets/images/di1.png")} alt='icon' />Dimension : 35ft. x 60ft.</span>
        <span><img src={require("../assets/images/di2.png")} alt='icon' />Plot Area : 2100 Sqft</span>
        <span><img src={require("../assets/images/di3.png")} alt='icon' />Location : Bangalore</span>
        <span><img src={require("../assets/images/di4.png")} alt='icon' />Duplex Floor Plan </span>
      </div>
      <button className='button mydesign-btn' onClick={togglePopup}>Need a Custom Design?</button>
      {isOpen &&
        <div className="get-consultation-box">
          <div className="box" style={{ maxHeight: "100%", marginTop: "20px" }}>
            <div className="box-info">
              <h3>Talk to a Designer</h3>
              <FontAwesomeIcon icon={faClose} onClick={togglePopup} className="close-icon" />
            </div>
            <Form className='contact-form consultation-form'>
              <Form.Group controlId="formBasicText">
                <Form.Control type="text" placeholder="Name" />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Control type="email" placeholder="Email" />
              </Form.Group>
              <Form.Group controlId="formBasicText">
                <Form.Control type="text" placeholder="Mobile Number" />
              </Form.Group>

              <div className='select-options'>
                <div className='head'>
                  <span onClick={() => setExpanded1(!expanded1)}>
                    Select Room
                    {expanded1 ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}
                  </span>
                </div>
                {expanded1 &&
                  <div className='options'>
                    <div><input type="radio" onChange={() => { }} /><label>Living Room</label></div>
                    <div><input type="radio" onChange={() => { }} /><label>Dining Room</label></div>
                    <div><input type="radio" onChange={() => { }} /><label>Bedroom</label></div>
                    <div><input type="radio" onChange={() => { }} /><label>Kitchen</label></div>
                    <div><input type="radio" onChange={() => { }} /><label>Bathroom</label></div>
                    <div><input type="radio" onChange={() => { }} /><label>Reading Room</label></div>
                    <div><input type="radio" onChange={() => { }} /><label>Playroom</label></div>
                    <div><input type="radio" onChange={() => { }} /><label>Other Room</label></div>
                    <div className='add-new-btns' style={{ margin: "0px" }}>
                      <button className='button btn-clear' style={{ marginLeft: "0px", marginRight: "12px" }}>Clear</button>
                      <button className='button'>Apply</button>
                    </div>
                  </div>
                }
              </div>

              <Form.Group controlId="ControlTextarea1" className='textarea'>
                <Form.Control as="textarea" rows={5} placeholder="Type description..." />
              </Form.Group>
            </Form>
            <button className="button">Get Consultation</button>
            <p className='mydesign-popup-text'>By sumbitting this form, you agree to the  <Link to={"/"}>Privacy Policy</Link> & <Link to={"/"}>terms and condition</Link></p>
          </div>
        </div>
      }
    </div>
  )

  const downloadDesign = (
    <div className='download-design'>
      <div>
        <h3>Plan</h3>
        <button className='button'><FontAwesomeIcon icon={faDownload} /> Download</button>
      </div>
      <div>
        <h3>Design</h3>
        <button className='button'><FontAwesomeIcon icon={faDownload} /> Download</button>
      </div>
      <div>
        <h3>3d Elevation</h3>
        <button className='button'><FontAwesomeIcon icon={faDownload} /> Download</button>
      </div>
      <div>
        <h3>BOQ</h3>
        <button className='button'><FontAwesomeIcon icon={faDownload} /> Download</button>
      </div>
    </div>
  )

  return (
    <Layout>
      <div>
        <section className='site-wrapper'>

          <div className='back-btn'>
            <FontAwesomeIcon icon={faArrowLeft} />
            <Link to={"/product-page"}>Back</Link>
          </div>

          <div className='details-page-hero'>
            <ImageSlider
              img1={require("../assets/images/dd01.png")}
              img2={require("../assets/images/dd02.png")}
              img3={require("../assets/images/dd03.png")}
              img4={require("../assets/images/dd04.png")}
              img5={require("../assets/images/dd05.png")}
            />
            <div className='details-info'>
              <h2>35x60 Home plan- 2100 sqft house floor plan</h2>
              <Tabs
                defaultActiveKey="projectDetails"
                style={{ marginTop: "30px" }}
                transition={false}
                mountOnEnter={false}
                className="my-designs-tabs"
              >
                <Tab eventKey="projectDetails" title="Project Details">{projectDetails}</Tab>
                <Tab eventKey="downloadDesign" title="Download Design">{downloadDesign}</Tab>
              </Tabs>
            </div>
          </div>
        </section>

        <ProductDetailsTable
          title="Description"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla faucibus curabitur leo a aliquet arcu, ullamcorper egestas at. Justo, duis tempor, libero fermentum ultrices fermentum in."
          PlotArea="2100 sqft"
          Cost="Moderate"
          TotalBuiltupArea="4200 sqft"
          Width="35 ft"
          Lenght="60 ft"
          BuildingType="Rental"
          BuildingCategory="House"
          EstimatedCostOfConstruction="63 - 72 Lakhs"
          BHK="2"
          PoojaRoom="2"
          LivingRoom="2"
          DrawingRoom="2"
          DiningRoom="2"
        />

      </div>
    </Layout>
  )
}

export default MyDesignsDetailsPage