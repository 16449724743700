import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Layout from '../components/layout'
import { Form, InputGroup } from 'react-bootstrap';
import { getProductDetails } from '../components/Redux/Product/action';
import { addPaymentRazorpay } from '../components/Redux/Razorpay/action';
import { orderPayment } from '../components/Redux/Payment/action';
import { ThreeDots } from 'react-loader-spinner';
import { getCustomerProfileData } from '../components/Redux/Profile/action';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import Svgloader from '../components/svgloader';
import FullScreenLoader from '../components/full-screen-loader';
const OrderSummery = () => {

  const { id } = useParams()
  const [productdetails, setProductdetails] = useState([])
  const user = JSON.parse(localStorage.getItem('user'))
  const [address, setAddress] = useState({})
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  useEffect(() => {
    const is_logged = localStorage.getItem('is_logged');
    if (is_logged != 'logged') {
      navigate('/')
    }
  }, [])

  useEffect(() => {
    getProductDetails(id)((response) => {
      if (response && response.status) {
        setProductdetails(response?.data?.results[0])

      }
    });
    getCustomerProfileData(true)((response) => {
      if (response && response.status && response.data?.results && response.data?.results[0]) {
        setAddress(response.data?.results[0])
        formik.setValues({
          ...formik.values,
          firstName: response?.data?.results && response?.data?.results[0] && response?.data?.results[0]?.user ? response?.data?.results[0]?.user : '',
          lastName: response?.data?.results && response?.data?.results[0] && response?.data?.results[0]?.user ? response?.data?.results[0]?.user : '',
          addressLine1: response?.data?.results && response?.data?.results[0] && response?.data?.results[0]?.address ? response?.data?.results[0]?.address : '',
          addressLine2: response?.data?.results && response?.data?.results[0] && response?.data?.results[0]?.address_line ? response?.data?.results[0]?.address_line : '',
          place: response?.data?.results && response?.data?.results[0] && response?.data?.results[0]?.place ? response?.data?.results[0]?.place : '',
          city: response?.data?.results && response?.data?.results[0] && response?.data?.results[0]?.locality ? response?.data?.results[0]?.locality : '',
          state: response?.data?.results && response?.data?.results[0] && response?.data?.results[0]?.state ? response?.data?.results[0]?.state : '',
          country: response?.data?.results && response?.data?.results[0] && response?.data?.results[0]?.district ? response?.data?.results[0]?.district : '',
          pin: response?.data?.results && response?.data?.results[0] && response?.data?.results[0]?.pincode ? response?.data?.results[0]?.pincode : '',
        })
      }
    });
  }, []);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('required'),
    lastName: Yup.string().required("required"),
    addressLine1: Yup.string().required("required"),
    addressLine2: Yup.string().required("required"),
    place: Yup.string().required("required"),
    city: Yup.string().required("required"),
    state: Yup.string().required("required"),
    country: Yup.string().required("required"),
    pin: Yup.string().required("required"),
  })

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      addressLine1: "",
      addressLine2: "",
      place: "",
      city: "",
      state: "",
      country: "",
      pin: ""
    },
    validationSchema,
    onSubmit: () => {
      handleSubmit(productdetails?.total, productdetails?.id)
    }
  })


  // Razorpay starts

  const handleSubmit = (amount, id) => {
    // e.preventDefault();

    if (amount === "") {
      alert("please enter amount");
    } else {
      var options = {
        key: "rzp_live_hedZ82EzXWRbn7",
        amount: amount * 100,
        currency: "INR",
        name: "STIKCON",
        description: "We take delight in executing projects with home design and remodeling in short order with top-notch designs at an affordable price.",
        handler: function (response) {
          console.log(response)
          setLoading(true)
          addPaymentRazorpay(response.razorpay_payment_id, amount)((response) => {
            if (response && response.status) {
              let formData = new FormData();
              formData.append("design", id);
              orderPayment({ formData })((response) => {
                if (response && response.status) {
                  navigate('/my-designs')
                  // setPayment(true)
                  setLoading(false)
                }
                else {
                  setLoading(false)
                }
              });
            }
          });
          // alert(response.razorpay_payment_id);
        },
        prefill: {
          name: "",
          email: "stikcon@gmail.com",
          contact: "8899002211"
        },
        notes: {
          address: "8th Floor, Bizcospaces,Infra Futura Building,Seaport Airport Road, Kakkanad, Kochi."
        },
        theme: {
          color: "#3399cc"
        }
      };
      var pay = new window.Razorpay(options);
      pay.open();
    }
  }

  const changeAddress = () => {
    navigate('/profile', { state: { fromSummery: true, homePlan: productdetails?.id } })
  }


  return (
    <Layout>
      <section className='site-wrapper order-summary-page'>
        {loading &&
          <div style={{ position: 'fixed', top: '50%', left: '50%', zIndex: '9999' }}>
            <Svgloader />
          </div>
        }
        <h3 className='order-summary-title'>Order Summery</h3>
        <div className='order-summary'>
          {/* <Form className='order-summery-form' onSubmit={formik.handleSubmit}> */}
          <div className='order-summary-form'>
            <div className='order-form'>
              <span>1</span>
              <div className='form-info'>
                <div>
                  <p>Login</p>
                  <h4>{user?.name}{" "}{user?.phone}</h4>
                </div>
                <button>Change</button>
              </div>
            </div>

            <div className='order-form'>
              <span style={{ backgroundColor: "var(--black)", color: "var(--white)" }}>2</span>
              <div style={{ width: "100%" }}>
                <div className='form-info'>
                  <div>
                    <h4 style={{ fontWeight: "600" }}>BILLING ADDRESS</h4>
                  </div>
                  <button onClick={() => changeAddress()}>Add / Change</button>
                </div>
                {
                  formik.values.addressLine1 &&
                  <div>
                    <Form className='order-summery-form'>
                      <InputGroup>
                        <Form.Control
                          placeholder="First name"
                          name='firstName'
                          aria-label="First name"
                          aria-describedby="basic-addon"
                          value={user?.name}
                          //onChange={formik.handleChange}
                          isInvalid={formik.errors?.firstName ? true : false}
                        />
                      </InputGroup>
                      <InputGroup>
                        <Form.Control
                          placeholder="Last name"
                          name='LastName'
                          aria-label="Last name"
                          aria-describedby="basic-addon"
                          value={user?.last_name}
                          //onChange={formik.handleChange}
                          isInvalid={formik.errors?.lastName ? true : false}
                        />
                      </InputGroup>
                      <InputGroup>
                        <Form.Control
                          placeholder="Address line 1"
                          name='addressLine1'
                          aria-label="Address line 1"
                          aria-describedby="basic-addon"
                          value={formik.values.addressLine1}
                          // onChange={formik.handleChange}
                          isInvalid={formik.errors?.addressLine1 ? true : false}
                        />
                      </InputGroup>
                      <InputGroup>
                        <Form.Control
                          placeholder="Address line 2"
                          name='addressLine2'
                          aria-label="Address line 2"
                          aria-describedby="basic-addon"
                          value={formik.values.addressLine2}
                          // onChange={formik.handleChange}
                          isInvalid={formik.errors?.addressLine2 ? true : false}
                        />
                      </InputGroup>
                      <InputGroup>
                        <Form.Control
                          placeholder="Place"
                          name='place'
                          aria-label="Place"
                          aria-describedby="basic-addon"
                          value={formik.values.place}
                          //onChange={formik.handleChange}
                          isInvalid={formik.errors?.place ? true : false}
                        />
                      </InputGroup>
                      <InputGroup>
                        <Form.Control
                          placeholder="City"
                          name='city'
                          aria-label="City"
                          aria-describedby="basic-addon"
                          value={formik.values.city}
                          // onChange={formik.handleChange}
                          isInvalid={formik.errors?.city ? true : false}
                        />
                      </InputGroup>
                      <InputGroup>
                        <Form.Control
                          placeholder="State"
                          name='state'
                          aria-label="State"
                          aria-describedby="basic-addon"
                          value={formik.values.state}
                          // onChange={formik.handleChange}
                          isInvalid={formik.errors?.state ? true : false}
                        />
                      </InputGroup>
                      <InputGroup>
                        <Form.Control
                          placeholder="Pin"
                          name='pin'
                          aria-label="Pin"
                          aria-describedby="basic-addon"
                          value={formik.values.pin}
                          //onChange={formik.handleChange}
                          isInvalid={formik.errors?.pin ? true : false}
                        />
                      </InputGroup>
                      <InputGroup>
                        <Form.Control
                          placeholder="Country"
                          name='country'
                          aria-label="Country"
                          aria-describedby="basic-addon"
                          value={formik.values.country}
                          //onChange={formik.handleChange}
                          isInvalid={formik.errors?.country ? true : false}
                        />
                      </InputGroup>
                    </Form>
                  </div>
                }
              </div>
            </div>
            {/* <div className='order-form'>
              <span>3</span>
              <div className='form-info'>
                <div>
                  <h4 style={{ color: "var(--muted)", fontWeight: "400", fontSize: "20px", marginLeft: "30px" }}>SELECT PAYMENT METHOD</h4>
                </div>
              </div>
            </div> */}

          </div>
          <div className='order-summary-info'>
            {/* <img src={productdetails?.image[0]?.image} alt='icon' className='order-summary-img' /> */}
            <h3>{productdetails?.title}</h3>
            <div className="order-summary-options">
              <span><img src={require("../assets/images/di1.png")} alt='icon' />Dimension : {productdetails?.dimensions} Sqft</span>
              {/* <span><img src={require("../assets/images/di2.png")} alt='icon' />Plot Area : 2100 Sqft</span> */}
              {productdetails?.location == "" ?
                <></>
                :
                <span><img src={require("../assets/images/di3.png")} alt='icon' />Location : {productdetails?.location}</span>
              }
              {/* <span><img src={require("../assets/images/di4.png")} alt='icon' />Duplex Floor Plan </span> */}
            </div>
            <div className='order-prices'>
              <div><h5>Price :</h5> <span>₹ {productdetails?.rate}</span></div>
              <div><h5>Discount :</h5> <span>- ₹ {productdetails?.discount}</span></div>
              <div><h5>Tax :</h5> <span>- ₹ {productdetails?.tax}</span></div>
            </div>
            <div className='order-prices'>
              <div><h5 style={{ fontWeight: "400", fontSize: "24px" }}>Total :</h5> <span style={{ fontWeight: "600", fontSize: "26px" }}>₹ {productdetails?.total}</span></div>
            </div>
            <div className='order-terms'>
              <Form.Check label="" name="group1" />
              <p>I agree to the <Link to="/">Terms & Conditions</Link></p>
            </div>
            <button className='button' type='button' onClick={(e) => formik.handleSubmit(e)}>Pay Now</button>
            
            {/* {payment &&
              <div className='downloads-info'>
                <h2>Files</h2>
                {productdetails?.dynamic?.map(file =>
                  file?.files != '' &&
                  <div className='download-desc mt-2'>
                    <span>{file?.name}</span>
                    <button onClick={() => downloadImage(file?.files, productdetails?.title + file?.name)}><FontAwesomeIcon icon={faDownload} /></button>
                  </div>
                )}
              </div>
            } */}
            {/* {loading &&
            <Svgloader text='Loading Homeplan..' />
            } */}
          </div>


          {/* </Form> */}
        </div>
      </section>
    </Layout>
  )
}

export default OrderSummery