import { CREATE_CUSTOMER_ADDRESS_URL, DELETE_CUSTOMER_ADDRESS_URL, DELETE_CUSTOMER_PROFILE_URL, GET_CUSTOMER_PROFILE_URL, UPDATE_CUSTOMER_EMAIL_NOTIFICATION_URL, UPDATE_CUSTOMER_PRIMARY_ADDRESS_URL, UPDATE_CUSTOMER_PROFILE_URL } from "../../api";
import axiosInstance from "../../axiosInstance";

export const getCustomerProfileData = (address) => onResponse => {
    try {
        axiosInstance.get(GET_CUSTOMER_PROFILE_URL + '?address=' + address)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const createCustomerAddress = (props) => onResponse => {
    try {
        axiosInstance.post(CREATE_CUSTOMER_ADDRESS_URL, props.formData)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const deleteCustomerProfile = (email) => onResponse => {
    try {
        axiosInstance.delete(DELETE_CUSTOMER_PROFILE_URL, { data: { email: email } })
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const updateCustomerProfile = (props) => onResponse => {
    try {
        axiosInstance.post(UPDATE_CUSTOMER_PROFILE_URL, props.formData)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const deleteUserAddress = (id) => onResponse => {
    try {
        axiosInstance.delete(DELETE_CUSTOMER_ADDRESS_URL, { data: { id: id } })
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const updateUserEmailNotification = (is_notification) => onResponse => {
    try {
        axiosInstance.post(UPDATE_CUSTOMER_EMAIL_NOTIFICATION_URL, { "is_notification": is_notification})
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const updateUserPrimaryAddress = (id) => onResponse => {
    try {
        axiosInstance.post(UPDATE_CUSTOMER_PRIMARY_ADDRESS_URL, { "address_id": id})
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};