import React from 'react'

const Services = () => {

  const servicesArr = [
    { id: 1, title: "POST YOUR QUERY", description: "You may quickly log in with your demands and see a list of available options." },
    { id: 2, title: "REQUIREMENT ANALYSIS", description: "The design has been filtered out and is available for selection. A close analysis of the design based on the needs like rooms, direction, area, etc. equips you to purchase the most perfect solution." },
    { id: 3, title: "PLACING YOUR ORDER", description: "Once you've decided on the design, an order can be placed, and the basic blueprint can be bought." },
    { id: 4, title: "THE DESIGN CURATION", description: "Detailed customization based on your purchase, choice & needs is initiated by the team. A requested meeting with the team makes your dream ideas come true, even faster & precise." },
  ]

  const services = servicesArr.map((sarr) => {
    return (
      <div key={sarr.id} className="services-wrap">
        <span className='service-num'>{sarr.id}</span>
        <h3>{sarr.title}</h3>
        <div className='service-hr'></div>
        <p>{sarr.description}</p>
      </div>
    )
  })

  return (
    <section className='site-wrapper index-section'>
      <div className='section-top'>
        <span>— SERVICES</span>
        <h3>Easy step to use the services</h3>
      </div>
      <div className='services'>
        {services}
      </div>
    </section>
  )
}

export default Services