import React, { Fragment, useEffect, useState } from "react";
import LoggedHeader from '../components/header/header';
import Footer from '../components/footer';
import { ToastContainer } from "react-toastify";

const Layout = ({ children, showLogin, setShowLogin, updatePage,setUpdatePage }) => {
  const [update, setUpdate] = useState(false);
  useEffect(() => {
    if (updatePage) {
      setUpdate(!update)
      setUpdatePage(false)
    }
  }, [updatePage])

  return (
    <Fragment>
      <LoggedHeader showLogin={showLogin} setShowLogin={setShowLogin} setUpdate={setUpdate} update={update} />
      <main style={{ marginTop: "120px" }}>
        {children}
      </main>
      <Footer />
    </Fragment>

  )
}

export default Layout