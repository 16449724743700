import { CUSTOMER_LOGIN_URL, CUSTOMER_LOGIN_VERIFY_OTP_URL, CUSTOMER_REGISTER_URL, CUSTOMER_VERIFY_OTP_URL } from "../../api";
import axiosInstance from "../../axiosInstance";

export const customerRegister = (props) => onResponse => {
    try {
        axiosInstance.post(CUSTOMER_REGISTER_URL, props.formData)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const customerVerifyOtp = (props) => onResponse => {
    try {
        axiosInstance.post(CUSTOMER_VERIFY_OTP_URL, props.formData)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const customerLogin = (props) => onResponse => {
    try {
        axiosInstance.post(CUSTOMER_LOGIN_URL, props.formData)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const customerLoginVerifyOtp = (props) => onResponse => {
    try {
        axiosInstance.post(CUSTOMER_LOGIN_VERIFY_OTP_URL, props.formData)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};