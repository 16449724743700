import React from 'react'
import '../../node_modules/slick-carousel/slick/slick.css';
import '../../node_modules/slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import Index from '../pages';

const ImageSlider = (props) => {
  const { imgs } = props
  const settings = {
    customPaging: function (i) {
      return (
        <span>
          {imgs[i].image_type === 'video' ?
            <video
              controls
              style={{ width: "100%", height: "100%", borderRadius: "10px" }}
            >
              <source
                src={imgs[i]?.image}
                type="video/mp4"
              />
            </video>
            :
            <img src={imgs[i].image}
              alt="Product-Detail" style={{ width: "100%", height: "100%", borderRadius: "10px" }} />
          }
        </span>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <div className='details-slides'>
      <Slider {...settings}>
        {imgs?.map((img, Index) =>
          img.image_type === 'video' ?
            <video
              controls
            >
              <source
                src={img?.image}
                type="video/mp4"
              />
            </video>
            :
            <img src={img.image} alt='Product-Detail' className='details-slide-img' />
        )}
        {/* <img src={img2} alt='Product-Detail' className='details-slide-img' />
        <img src={img3} alt='Product-Detail' className='details-slide-img' />
        <img src={img4} alt='Product-Detail' className='details-slide-img' />
        <img src={img5} alt='Product-Detail' className='details-slide-img' /> */}
      </Slider>
    </div>
  )
}

export default ImageSlider