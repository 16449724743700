import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom'
import { listCategoryRooms } from '../Redux/Home/action';
import { BASE_URL_DESIGN } from '../api';

const InteriorDesigns = () => {
  const [rooms, setRooms] = useState([])


  useEffect(() => {
    listCategoryRooms()((response) => {
      if (response && response.status) {
        setRooms(response?.data?.results)
      }
    });
  }, [])

  const interiorDesigns = rooms.map((parr) => {
    return (
      <a href={BASE_URL_DESIGN} target='_blank' rel="noreferrer">
        <div key={parr.id} className="overlay-image">
          <h3 className='overlay-image-title'>{parr.room_title}</h3>
          <img src={parr?.room_image} alt={parr.room_title} />
        </div>
      </a>)

  })

  return (
    <section className='site-wrapper index-section' style={{ marginBottom: "60px" }}>
      <div className='interior-section-top'>
        <div className='section-top'>
          <span>— TOP OF</span>
          <h3>Interior Designs</h3>
        </div>
        <a href={BASE_URL_DESIGN} target='_blank' rel="noreferrer">Explore All <FontAwesomeIcon icon={faLongArrowRight} /></a>
      </div>
      <div className='section-images'>
        {interiorDesigns}
      </div>
    </section>
  )
}

export default InteriorDesigns