import { ADD_PAYMENT_RAZORPAY } from "../../api";
import axiosInstance from "../../axiosInstance";

export const addPaymentRazorpay = (payment_id, amount) => onResponse => {
    try {
        axiosInstance.post(ADD_PAYMENT_RAZORPAY, { payment_id, amount })
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};