import { GET_CUSTOMER_WISHLIST_URL, LIST_CUSTOMER_DESIGNS_URL, REMOVE_WISHLIST_CUSTOMER_DESIGNS_URL, WISHLIST_CUSTOMER_DESIGNS_URL } from "../../api";
import axiosInstance from "../../axiosInstance";

export const getCustomerWishList = () => onResponse => {
    try {
        axiosInstance.get(GET_CUSTOMER_WISHLIST_URL + '?interior=' + false)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const listCustomerDesigns = (id) => onResponse => {
    try {
        axiosInstance.get(LIST_CUSTOMER_DESIGNS_URL +  '?user_id=' + id)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const wishListCustomerDesign = (props) => onResponse => {
    try {
        axiosInstance.post(WISHLIST_CUSTOMER_DESIGNS_URL, props.formData)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const removeWishListCustomerDesign = (id) => onResponse => {
    try {
        axiosInstance.delete(REMOVE_WISHLIST_CUSTOMER_DESIGNS_URL, { data: { home_plan: id } })
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};