
import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faDownload } from '@fortawesome/free-solid-svg-icons';
import { downloadOrderInvoice, orderInvoiceDetails } from './Redux/Payment/action';


const Invoice = ({ handleClick, paymentId }) => {


  const [payment, setPayment] = useState({});
  const user = JSON.parse(localStorage.getItem('user'));
  useEffect(() => {
    if (paymentId && paymentId != '') {
      orderInvoiceDetails(paymentId, user?.id)((response) => {
        if (response && response.status) {
          setPayment(response.data?.results[0])
        }
      });
    }
  }, [paymentId])
  const onDownloadInvoice = () => {

    downloadOrderInvoice(paymentId)((response) => {
      if (response && response.status) {
        // let a = document.createElement('a');
        // a.href = response.data[0]?.invoice_pdf;
        // a.target = '_blank';
        // a.download = true;
        // a.click();
        let data = response.data[0]?.invoice_pdf;
        data = data.replace('http://', 'https://');
        console.log(data);
        fetch(data)
          .then(response => {
            response.blob().then(blob => {
              let url = window.URL.createObjectURL(blob);
              let a = document.createElement('a');
              a.href = url;
              a.download = 'incvoice-data';
              a.click();
            });
          });

      }
    });
  }


  return (
    <div className="invoice-box">
      <div className='invoice-info-box'>
        <div className='invoice-head'>
          <div className='d-flex justify-content-end'>
            <FontAwesomeIcon icon={faClose} onClick={handleClick} />
          </div>
          <div className='logo-section'>
            <img src={require("../assets/images/logo.png")} alt='logo' />
            <div>
              <span onClick={onDownloadInvoice}><FontAwesomeIcon icon={faDownload} />Pdf</span>
              {/* <FontAwesomeIcon icon={faClose} onClick={handleClick} /> */}
            </div>
          </div>
          <div className='invoice-data'>
            <div>
              <h5>Invoice Number:</h5>
              <p>{payment?.number}</p>
            </div>
            <div>
              <h5>Product ID:</h5>
              <p>{payment?.item_details?.id}</p>
            </div>
            <div>
              <h5>Order Number:</h5>
              <p>{payment?.order_number}</p>
            </div>
            <div>
              <h5>Invoice Date:</h5>
              <p>{payment?.order_created_date}</p>
            </div>
            <div>
              <h5>Nature Of Transaction:</h5>
              <p>Intra-State</p>
            </div>
            <div>
              <h5>Order Date:</h5>
              <p>{payment?.order_created_date}</p>
            </div>
            <div>
              <h5>Place Of Supply:</h5>
              <p>Online</p>
            </div>
            <div>
              <h5>Nature Of Supply:</h5>
              <p>Online</p>
            </div>
          </div>
        </div>

        <div className='site-wrapper bill-info'>
          <div>
            <h3>Bill From:</h3>
            <div>
              <h4>Stikcon Pvt. Ltd.</h4>
              <p>8th Floor, Bizcospaces,Infra Futura Building,Seaport Airport Road, Kakkanad, Kochi, Kerala-682021</p>
              <div className='item-data'>
                <div><span>GSTIN Number:</span><p>947HFYD976874578</p></div>
                <div><span>Item Name:  </span><p>{payment?.item_details?.title}</p></div>
                <div><span>HSN:</span><p>867489329. 5.0% IGST</p></div>
              </div>
            </div>
          </div>
          <div>
            <h3>Bill To/Ship To:</h3>
            <div>
              <h4>{payment?.address?.user?.name}</h4>
              <p> {payment?.address?.address + ',' + payment?.address?.place + ',' + payment?.address?.district + ',' + payment?.address?.state}</p>
            </div>
          </div>
        </div>
        {/* Table */}
        <div style={{ overflowX: "auto" }}>
          <table className='invoice-table'>
            <tr>
              <th></th>
              <th>SL NO.</th>
              <th>Gross Amount</th>
              <th>Discount</th>
              <th>Other charges</th>
              <th>Taxable Amount</th>
              <th>IGST</th>
              <th>Total Amount</th>
            </tr>


            <tr className="invoice-info">
              <td></td>
              <td>1</td>
              <td>{payment?.gross_amount}</td>
              <td>{payment?.discount}</td>
              <td>{payment?.other_charges}</td>
              <td>{payment?.taxable_amount}</td>
              <td>{payment?.igst}</td>
              <td>{payment?.total_amount}</td>
            </tr>

            <tr>
              <td></td>
              <td>Total</td>
              <td>{payment?.gross_amount}</td>
              <td>{payment?.discount}</td>
              <td>{payment?.other_charges}</td>
              <td>{payment?.taxable_amount}</td>
              <td>{payment?.igst}</td>
              <td>{payment?.total_amount}</td>
            </tr>
          </table>
        </div>
        <div className='site-wrapper'>
          <div className='invoice-footer'>
            <div className='invoice-sign'>
              <h3>Stikcon Pvt. Ltd.</h3>
              <img src={require("../assets/images/sign.png")} alt='sign' />
              <p>Authorised Signatory</p>
            </div>
            <div className='invoice-qr'>
              <img src={require("../assets/images/or.png")} alt='qr' />
            </div>
          </div>
          <p className='invoice-copyright'>If you have any questions, feel free to call customer care at <b>+91 9211420420</b> or use <b>Contact Us</b> section in our App, log on to <b>www.stikcon.com/contactus.</b></p>
        </div>
      </div>
    </div>
  )
}
export default Invoice