import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faLocationDot, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { BsHeartFill } from 'react-icons/bs';

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='site-wrapper footer-wrapper'>
        <div className='footer-logo'>
          <img src={require("../assets/images/stikcon-logo.png")} alt='logo' />
          <p>We take delight in executing projects with home design and remodeling in short order with top-notch designs at an affordable price. Contact us to schedule an online consultation, look through our design ideas and stories, blog, and get in contact with our designers.</p>
          <h4 className='socialmedia_title'>Show us some <BsHeartFill color='#B01E28' /> on social media</h4>
          <ul>
            <li><a href="https://www.facebook.com/stikconpmc" target='_blank' rel="noreferrer"><FontAwesomeIcon icon={faFacebook} className="icon-color" /> </a></li>
            <li><a href="https://twitter.com/i/flow/login?redirect_after_login=%2Fstikconpmc%2F" target='_blank' rel='noreferrer'><FontAwesomeIcon icon={faTwitter} className="icon-color" /> </a></li>
            <li><a href="https://www.instagram.com/stikcon/?hl=en" target='_blank' rel='noreferrer'><FontAwesomeIcon icon={faInstagram} className="icon-color" /> </a></li>
          </ul>
        </div>
        <div className='footer-links'>
          <h3>Quick Access</h3>
          <ul>
            <li><Link to={"/"}>Home</Link></li>
            <li><Link to={"/about-us"}>About Us</Link></li>
            <li><Link to={"/faq"}>FAQ</Link></li>
          </ul>
        </div>
        <div className='footer-links'>
          <h3>Service</h3>
          <ul>
            <li><Link to={"/help"}>Help & Support</Link></li>
            <li><Link to={"/contact"}>Contact Us</Link></li>
          </ul>
        </div>
        <div className='footer-links footer-contacts'>
          <h3>Contact us</h3>
          <ul>
            <li><FontAwesomeIcon icon={faPhone} className="icon-color" /><span>+91 8899002211</span></li>
            <li><FontAwesomeIcon icon={faEnvelope} className="icon-color" /><span> stikcon@gmail.com</span></li>
            <li><FontAwesomeIcon icon={faLocationDot} className="icon-color" /><span>
              8th Floor, Bizcospaces,Infra Futura Building,Seaport Airport Road, Kakkanad, Kochi, Kerala-682021</span>
            </li>
          </ul>
        </div>
      </div>
      <div className='footer-bottom'>
        <p>All rights reserved@stikcon2022</p>
      </div>
    </footer>
  )
}

export default Footer