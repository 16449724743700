import { CREATE_CONTACT_US_URL, CREATE_NEED_SUPPORT_URL, GET_DIRECTIONS_DROPDOWN_URL, GET_TESTIMONIAL_DETAILS, LIST_BROCHURE_URL, LIST_CATEGORY_ROOM_URL, LIST_FAQ_URL, LIST_PROPERTY_TYPES_URL, LIST_SOLUTIONS_URL } from "../../api";
import axiosInstance from "../../axiosInstance";

export const listFaq = (search) => onResponse => {
    try {
        axiosInstance.get(LIST_FAQ_URL + '?search=' + search)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};

export const createContactUs = (props) => onResponse => {
    try {
        axiosInstance.post(CREATE_CONTACT_US_URL, props.formData)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getTestimonialDetails = () => onResponse => {
    try {
        axiosInstance.get(GET_TESTIMONIAL_DETAILS)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const listCategoryRooms = () => onResponse => {
    try {
        axiosInstance.get(LIST_CATEGORY_ROOM_URL)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const listPropertyTypes = () => onResponse => {
    try {
        axiosInstance.get(LIST_PROPERTY_TYPES_URL)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};

export const listAllDirections = () => onResponse => {
    try {
        axiosInstance.get(GET_DIRECTIONS_DROPDOWN_URL)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const createNeedSupport = (props) => onResponse => {
    try {
        axiosInstance.post(CREATE_NEED_SUPPORT_URL, props.formData)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const listSolutions = () => onResponse => {
    try {
        axiosInstance.get(LIST_SOLUTIONS_URL)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const listBrochure = () => onResponse => {
    try {
        axiosInstance.get(LIST_BROCHURE_URL)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};