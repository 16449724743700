// import { LottiePlayer } from '@lottiefiles/lottie-player'
import React from 'react'
import * as LottiePlayer from "@lottiefiles/lottie-player";
// import {nodata} from '../assets/json/nodata.json'
const LottieAnimation = ({text}) => {
    return (
        <div className='d-flex flex-column align-items-center justify-content-center'>
            <lottie-player
                autoplay
                loop
                mode="normal"
                src={'https://assets3.lottiefiles.com/private_files/lf30_dzn8fp9a.json'}
                style={{ width: "320px" }}
            ></lottie-player>
            <div className='no-data-text'>{text ? text : 'No data'}</div>
        </div>
    )
}

export default LottieAnimation