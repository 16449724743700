import React, { Fragment, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Layout from '../components/layout';
import LottieAnimation from '../components/lottie-animation';
import { listCustomerDesigns } from '../components/Redux/Design/action';
import Svgloader from '../components/svgloader';
// import ProductCard from '../components/product-card';

const MyDesigns = () => {
  const [designs, setDesigns] = useState([])
  const [loading, setLoading] = useState(true)

  const navigate = useNavigate();
  useEffect(() => {
    const is_logged = localStorage.getItem('is_logged');
    if (is_logged != 'logged') {
      navigate('/')
    }
  }, [])
  useEffect(() => {
    setLoading(true)
    const user = JSON.parse(localStorage.getItem('user'));
    listCustomerDesigns(user?.id)((response) => {
      if (response && response.status) {
        setDesigns(response?.data?.results)
        setLoading(false)
      }
      else {
        setLoading(false)
      }
    });
  }, [])

  return (
    <Layout>
      <div className='site-wrapper'>
        <h3 className='my-designs-title'>My Designs</h3>
        {loading ?
          <div className='min-height-400'>
            <Svgloader />
          </div>
          :
          <Fragment>
            {designs?.length == 0 ?
              <Fragment>
                <LottieAnimation text='No data' />
              </Fragment>
              :
              <div className='my-designs-cards'>
                {designs?.map((design, index) =>
                  <div className="product-card" key={index}>
                    <img src={design?.image && design?.image[0] && design?.image[0].image && design?.image[0].image} wi alt='design' />
                    <div>
                      <h3>{design?.title}</h3>
                      <button className='button'><Link to={'/product-details-page/' + design?.id}>View Details</Link></button>
                    </div>
                  </div>
                )}
              </div>
            }
          </Fragment>
        }
      </div>
    </Layout>
  )
}

export default MyDesigns