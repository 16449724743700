import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/styles.scss';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Home from "./pages/index"
import ProductPage from "./pages/product-page"
import ProductDetailsPage from './pages/product-details-page';
import OrderSummery from './pages/order-summery';
import MyDesigns from './pages/my-designs';
import MyFavourites from './pages/my-favourites';
import MyDesignsDetailsPage from './pages/mydesign-details-page';
import Faq from './pages/faq';
import Contact from './pages/contact.js';
import AboutUs from './pages/about-us';
import Help from './pages/help';
import Profile from './pages/profile';
import Payments from './pages/payments';
import { useLayoutEffect } from 'react';
import ErrorPage from './components/404-Page/404-page';
import RazorpayIntegration from './components/RazorpayIntegration';
import Viewallnotification from './components/viewallnotification';

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
}

function App() {
  return (
    <Router>
      <Wrapper>
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route path='/home' element={<Home />} />
          <Route path='/product-page' element={<ProductPage />} />
          <Route path='/product-details-page/:id' element={<ProductDetailsPage />} />
          <Route path='/order-summery/:id' element={<OrderSummery />} />
          <Route path='/my-favourites' element={<MyFavourites />} />
          <Route path='/my-designs' element={<MyDesigns />} />
          <Route path='/mydesign-details-page' element={<MyDesignsDetailsPage />} />
          <Route path='/faq' element={<Faq />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/about-us' element={<AboutUs />} />
          <Route path='/help' element={<Help />} />
          <Route path='/profile' element={<Profile />} />
          <Route path='/payments' element={<Payments />} />
          <Route path='/viewallnotification' element={<Viewallnotification />} />
          <Route path='/razorpay' element={<RazorpayIntegration />} />
          <Route exact path='/*' element={<ErrorPage />} />
        </Routes>
      </Wrapper>
    </Router>
  );
}

export default App;
