import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faLocationDot, faHouse, faMinus } from "@fortawesome/free-solid-svg-icons";
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { listAllDirections, listPropertyTypes } from '../Redux/Home/action';
import { FaEye } from "react-icons/fa";
import dimension from '../../assets/images/DIMENSION GUIDE FOR SITE MEASUREMENT 2.jpg';

const ProductForm = (props) => {
  const { formik, clearFormData } = props;

  const [show, setShow] = useState(false);
  const [types, setTypes] = useState([])
  const [directions, setDirections] = useState([])

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    listPropertyTypes()((response) => {
      if (response && response.status) {
        setTypes(response?.data?.results)
      }
    });
    listAllDirections()((response) => {
      if (response && response.status) {
        setDirections(response?.data)
      }
    });
  }, [])



  return (
    <div style={{ margin: "30px 0px" }}>
      <Form onSubmit={formik.handleSubmit} onReset={formik.handleReset} >
        <div className='product-form'>
          <InputGroup className='product-tabs-select product-tabs-select3 '>
            <Form.Control
              placeholder="Facing Width"
              aria-label="Width"
              name='width'
              onChange={formik.handleChange}
              value={formik.values?.width}
              aria-describedby="basic-addon2"
              style={{ padding: "8px" }}
            />
            <InputGroup.Text className='sqft-text remove-input-style'>M</InputGroup.Text>
          </InputGroup>

          <InputGroup className='product-tabs-select product-tabs-select3 '>
            <Form.Control
              placeholder="Back Width"
              aria-label="Width"
              name='back_width'
              onChange={formik.handleChange}
              value={formik.values?.back_width}
              aria-describedby="basic-addon2"
              style={{ padding: "8px" }}
            />
            <InputGroup.Text className='sqft-text remove-input-style'>M</InputGroup.Text>
          </InputGroup>

          {/* <InputGroup.Text className='remove-input-style' style={{ background: "transparent", border: "none", paddingLeft: "0px", paddingRight: "0px" }}>
            <FontAwesomeIcon icon={faMinus} />
          </InputGroup.Text> */}

          <InputGroup className='product-tabs-select product-tabs-select3'>
            <Form.Control
              placeholder="Right Length"
              aria-label="Length"
              name='length'
              onChange={formik.handleChange}
              value={formik.values?.length}
              aria-describedby="basic-addon2"
              style={{ padding: "8px" }}
            />
            <InputGroup.Text className='sqft-text remove-input-style'>M</InputGroup.Text>
          </InputGroup>

          <InputGroup className='product-tabs-select product-tabs-select3'>
            <Form.Control
              placeholder="Left Length"
              aria-label="Length"
              name='left_length'
              onChange={formik.handleChange}
              value={formik.values?.left_length}
              aria-describedby="basic-addon2"
              style={{ padding: "8px" }}
            />
            <InputGroup.Text className='sqft-text remove-input-style'>M</InputGroup.Text>
          </InputGroup>

          <InputGroup className='product-tabs-select product-tabs-select3'>
            <Form.Control
              placeholder="Diagonal Length"
              aria-label="Length"
              name='diagonal_length'
              onChange={formik.handleChange}
              value={formik.values?.diagonal_length}
              aria-describedby="basic-addon2"
              style={{ padding: "8px" }}
            />
            <InputGroup.Text className='sqft-text remove-input-style'>M</InputGroup.Text>
          </InputGroup>

          <InputGroup className='product-tabs-select'>
            <Form.Select aria-label="Default select example" name='direction' onChange={formik.handleChange} value={formik.values?.direction}>
              <option value="">Direction</option>
              {directions?.map(direction =>
                <option value={direction?.value}>{direction?.label}</option>
              )}
            </Form.Select>
          </InputGroup>

          {/* <InputGroup className='product-tabs-select'>
          <Form.Select aria-label="Default select example">
            <option>Land Type</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </Form.Select>
        </InputGroup> */}

          {/* <InputGroup className='product-tabs-select product-tabs-select2' style={{padding: "2px 0px"}}>
          <Form.Select aria-label="Default select example">
            <option>Floors</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </Form.Select>
        </InputGroup> */}

          <InputGroup className='product-tabs-input'>
            <InputGroup.Text className='remove-input-style'>
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </InputGroup.Text>
            <Form.Control
              placeholder="Type your search"
              aria-label="search"
              name='search'
              onChange={formik.handleChange}
              value={formik.values?.search}
            />
          </InputGroup>

          {/* <InputGroup className='product-tabs-input'>
          <InputGroup.Text className='remove-input-style'>
            <FontAwesomeIcon icon={faLocationDot} />
          </InputGroup.Text>
          <Form.Control
            placeholder="Location"
            aria-label="Location"
          />
        </InputGroup> */}

          <InputGroup className='product-tabs-select'>
            <InputGroup.Text className='remove-input-style'>
              <FontAwesomeIcon icon={faHouse} />
            </InputGroup.Text>
            <Form.Select
              aria-label="Default select example"
              name='property_type'
              onChange={formik.handleChange}
              value={formik.values?.property_type}
            >
              <option value=''>Property Type</option>
              {types?.map(type =>
                <option value={type?.id}>{type?.name}</option>
              )}

            </Form.Select>
          </InputGroup>

          <div className='help_icon_wrapper'>
            <FaEye className='help_icon' onClick={handleShow} />
          </div>

          <button className='button resBtn'
            style={{
              padding: "12px 50px", marginTop: "0px",
              backgroundImage: 'linear-gradient(to right, #E0F0FD, #cdfaef)', color: "var(--primary)"
            }}
            type='button'
            onClick={clearFormData}
          >
            Clear
          </button>

          <button
            className='button resBtn'
            style={{ padding: "12px 50px", marginTop: "0px" }}
            type='submit'
          >
            Search
          </button>
        </div>
      </Form>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className='design_plan_modal'
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <img src={dimension} className='img-fluid' alt='Dimenison Guide' />
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default ProductForm