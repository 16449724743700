import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom'
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import ProductCard from '../product-card';
import { getAllProductDetails } from '../Redux/Product/action';


const HouseDesigns = () => {
  const [products, setProducts] = useState([])
  var settings = {
    infinite: true,
    arrows: false,
    speed: 700,
    autoplay: false,
    slidesToShow: 1,  
    slidesToScroll: 1,
    variableWidth: true,
    responsive: [
      {
          breakpoint: 480,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
          }
      }
  ]
  };
  useEffect(() => {
    getAllProductDetails(1, 3)((response) => {
      if (response && response.status) {

        setProducts(response?.data?.results)
      }
    });
  }, [])




  const houseDesigns = products?.map((product, index) => {
    return (
      <div className="house-design-card" key={index}>
        <img src={product.image && product.image[0] && product.image[0].image && product.image[0].image} alt={product?.title} />
        <div className="design-card-info">
          <h3>{product?.title}</h3>
          <div className="design-card-options">
            <div><img src={require("../../assets/images/di5.png")} alt='icon' /><span>{product?.width}{' ft x '}{product?.length}{' ft'}</span></div>
            <div><img src={require("../../assets/images/di6.png")} alt='icon' /><span>{product?.dimensions + ' Sqft'}</span></div>
            <div><img src={require("../../assets/images/di7.png")} alt='icon' /><span>{product?.property_name}</span></div>
          </div>
          <button className='button'><Link to={'/product-details-page/' + product?.id}>View Details</Link></button>
        </div>
      </div>
    )
  })

  return (
    <section className='site-wrapper index-section' style={{ paddingBottom: "120px", marginBottom: "60px", background: "linear-gradient(to bottom, var(--white) 50%, var(--black) 50%" }}>
      <div className='interior-section-top'>
        <div className='section-top'>
          <span>— TOP OF</span>
          <h3>House Designs</h3>
        </div>
        <Link to={"/product-page"}>Explore All <FontAwesomeIcon icon={faLongArrowRight} /></Link>
      </div>
      <div className='section-images house-section-hide'>
        {houseDesigns}
      </div>
      <div className="house-slide-hide">
        <Slider {...settings} >
          {houseDesigns}
        </Slider>
      </div>
    </section>
  )
}

export default HouseDesigns