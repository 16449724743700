import { DOWNLOAD_ORDER_PAYMENT_URL, GET_PAYMENT_LIST_URL, ORDER_PAYMENT, ORDER_PAYMENT_DETAILS_URL } from "../../api";
import axiosInstance from "../../axiosInstance";

export const getPaymentListing = (id) => onResponse => {
    try {
        axiosInstance.get(GET_PAYMENT_LIST_URL + '?user_id=' + id)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};

export const orderPayment = (props) => onResponse => {
    try {
        axiosInstance.post(ORDER_PAYMENT, props.formData)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const orderInvoiceDetails = (id,user_id) => onResponse => {
    try {
        axiosInstance.get(ORDER_PAYMENT_DETAILS_URL + '?order_id=' + id + '&ecom_invoice=true' + '&user_id='+user_id)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const downloadOrderInvoice = (id) => onResponse => {
    try {
        axiosInstance.get(DOWNLOAD_ORDER_PAYMENT_URL + '?order_id=' + id)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
