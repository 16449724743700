import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Form } from 'react-bootstrap';

const Signup = (props) => {
  const { activeView, handleClose, formik } = props;
  return (
    <div className="register-box">
      <div className="register-info">
        <div>
          <h3>Register</h3>
          <FontAwesomeIcon icon={faClose} onClick={handleClose} className="close-icon" />
        </div>
        <p>Welcome! Please enter your details</p>
      </div>
      <Form onSubmit={formik.handleSubmit}>
        <div className='contact-form register-form'>
          <Form.Group controlId="formBasicText">
            <Form.Control type="text" name="name" onChange={formik.handleChange} isInvalid={formik?.errors.name ? true : false} placeholder="First Name" />
          </Form.Group>

          <Form.Group controlId="formBasicText">
            <Form.Control type="text" name="last_name" onChange={formik.handleChange} isInvalid={formik?.errors.last_name ? true : false} placeholder="Last Name" />
          </Form.Group>

          <Form.Group controlId="formBasicEmail">
            <Form.Control type="email" name="email" onChange={formik.handleChange} isInvalid={formik?.errors.email ? true : false} placeholder="Email" />
          </Form.Group>

          <Form.Group controlId="formBasicText">
            <Form.Control type="text" name="phone" onChange={formik.handleChange} isInvalid={formik?.errors.phone ? true : false} placeholder="Phone Number" />
          </Form.Group>

          <Form.Group controlId="formBasicText">
            <Form.Control type="text" name="location" onChange={formik.handleChange} isInvalid={formik?.errors.location ? true : false} placeholder="Location" />
          </Form.Group>
          <Form.Group controlId="formBasicText">
            <Form.Control type="text" name="web_code" onChange={formik.handleChange} isInvalid={formik?.errors.web_code ? true : false} max={3} placeholder="Country web code" />
          </Form.Group>

          <Form.Group controlId="formBasicText">
            <Form.Control type="text" placeholder="Captcha" value="U H 7  x c 1" style={{ fontWeight: "600" }} />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Control type="text" name="capcha" onChange={formik.handleChange} isInvalid={formik?.errors.capcha ? true : false} placeholder="Enter Captcha" />
          </Form.Group>
        </div>
        <div className="register-box-info">
          <Form.Check label="" name="group1" />
          <p className="sign-up-text">I agree to the<span>Terms & Conditions</span></p>
        </div>
        <button className="button" type='submit'>Create Account</button>
      </Form>
      <p className="sign-up-text">Already have an account?<span onClick={() => activeView("Login")}>Login</span></p>
    </div>
  )
}

export default Signup