import React, { useState, useEffect } from "react";
import Services from '../components/index/services';
import HouseDesigns from '../components/index/house-designs';
import OurServices from '../components/index/our-services';
import InteriorDesigns from '../components/index/interior-designs';
import PopularProperty from '../components/index/popular-property';
import Testimonials from '../components/index/testimonials';
import Layout from "../components/layout";
import TabsSection from '../components/index/tabs-section';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const Index = () => {

  const [activeView, setActiveView] = useState(false)
  const { state } = useLocation()
  let profileDelete = state?.profileDelete ? state?.profileDelete : false;
  useEffect(() => {
    setTimeout(() => {
      setActiveView(true)
    }, 1000)

  }, [])
  useEffect(() => {
    if (profileDelete) {
      profileDelete = false;
      window.history.replaceState({}, document.title)
      toast.success("Profile deleted successfully", {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }, [])

  const OfferPopup = () => {
    return (
      <div className="popup-box">
        <div className="offer-box">
          <div>
            <img src={require("../assets/images/offer.png")} alt='offer' />
            <div className="offer-info">
              <FontAwesomeIcon icon={faClose} onClick={() => setActiveView(!activeView)} className="close-icon" />
              <h2>Get 20% off </h2>
              <h3>on new year sale!</h3>
              <span>3456tc</span>
              <button className="button" style={{ padding: "14px 30px", fontSize: "18px" }}>Apply Discount</button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Layout>
      {/* {activeView && <OfferPopup />} */}
      <ToastContainer style={{ zIndex: 99999 }} />

      <div className='hero-section'>
        <div className='hero-wrap'>
          <div className='hero-info'>
            <h2>Discover the superior reflection of your dream space through the STIK lens!</h2>
            {/* <h4>(Discover the best for your dream home space.)</h4> */}
            <p>The life of your dreams! This is a journey, a brief encounter, through our carefully curated AI-based space design realm.</p>
            <p>We, as a team, offer you a range of solutions that cater to your special spatial ideas in a unique, efficient & precise way.</p>
          </div>
          <div className='hero-img'>
            <img src={require("../assets/images/fetured-img.png")} alt='logo' />
          </div>
        </div>
        <div className="tabs-mobile-view"><TabsSection /></div>
      </div>
      <div className="tabs-desktop-view"><TabsSection /></div>
      <PopularProperty />
      <Services />
      <HouseDesigns />
      <OurServices />
      <Testimonials />
      <InteriorDesigns />
    </Layout>
  )
}

export default Index