import React from 'react'

const ProductDetailsTable = (props) => {
  const { productslist } = props;
  return (
    <section className='site-wrapper'>
      <div className='product-details-tables-info'>
        <h3>{productslist?.title}</h3>
        <p>{productslist?.description}</p>
      </div>
      <h3 style={{ fontWeight: "600" }}>Details</h3>
      <div className='product-details-tables'>
        <table className='product-details-table'>
          <tbody>
            <tr>
              <th>Plot area</th><td>{productslist?.dimensions}</td>
            </tr>
            <tr>
              <th>Cost</th><td>{productslist?.total}</td>
            </tr>
            <tr>
              <th>Total builtup area</th><td>{productslist?.dimensions}</td>
            </tr>
            <tr>
              <th>Facing Width</th><td>{productslist?.width}</td>
            </tr>
            <tr>
              <th>Back Width</th><td>{productslist?.back_width}</td>
            </tr>
            <tr>
              <th>Right Length</th><td>{productslist?.length}</td>
            </tr>
            <tr>
              <th>Left Length</th><td>{productslist?.left_length}</td>
            </tr>
            <tr>
              <th>Diagonal Length</th><td>{productslist?.diagonal_length}</td>
            </tr>
            <tr>
              <th>Building type</th><td>{productslist?.property_name}</td>
            </tr>
            {/* <tr>
              <th>Building categoryost</th><td>{productslist?.dimensions}</td>
            </tr> */}

          </tbody>
          <tbody>
            <tr>
              <th>BHK</th><td>{productslist?.bhk}</td>
            </tr>
            <tr>
              <th>Bed rooms</th><td>{productslist?.bed_rooms}</td>
            </tr>
            <tr>
              <th>Bath rooms</th><td>{productslist?.bath_rooms}</td>
            </tr>
            <tr>
              <th>Balcony</th><td>{productslist?.balcony}</td>
            </tr>
            <tr>
              <th>Prayer room</th><td>{productslist?.prayer_room == 1 ? 'Yes' : 'No'}</td>
            </tr>
            <tr>
              <th>Living area</th><td>{productslist?.drawing_area == 1 ? 'Yes' : 'No'}</td>
            </tr>
            <tr>
              <th>Car parking</th><td>{productslist?.car_parking == 1 ? 'Yes' : 'No'}</td>
            </tr>
            <tr>
              <th>Estimated cost of construction</th><td>{productslist?.estimated_cost}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  )
}

export default ProductDetailsTable