import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Form } from 'react-bootstrap'

const AddressModal = (props) => {
    const { formik, handleClose,disableBtn } = props;
    return (
        <div className="popup-box" style={{overflowX : 'auto'}}>
            <div className="register-box">
                <div className="register-info">
                    <div>
                        <h3>Address</h3>
                        <FontAwesomeIcon icon={faClose} onClick={handleClose} className="close-icon" />
                    </div>
                </div>
                <Form onSubmit={formik.handleSubmit}>
                    <div className='contact-form register-form' >
                        <Form.Group controlId="formBasicText">
                            <Form.Control type="text" name="place" onChange={formik.handleChange} isInvalid={formik.errors?.place} placeholder="Place" />
                        </Form.Group>

                        <Form.Group controlId="formBasicText">
                            <Form.Control type="text" name="address" onChange={formik.handleChange} isInvalid={formik.errors?.address} placeholder="Address" />
                        </Form.Group>

                        <Form.Group controlId="formBasicEmail">
                            <Form.Control type="text" name="address_line" onChange={formik.handleChange} isInvalid={formik.errors?.address_line} placeholder="Address line" />
                        </Form.Group>

                        <Form.Group controlId="formBasicText">
                            <Form.Control type="text" name="locality" onChange={formik.handleChange} isInvalid={formik.errors?.locality} placeholder="Locality" />
                        </Form.Group>

                        <Form.Group controlId="formBasicText">
                            <Form.Control type="text" name="landmark" onChange={formik.handleChange} isInvalid={formik.errors?.landmark} placeholder="Landmark" />
                        </Form.Group>
                        <Form.Group controlId="formBasicText">
                            <Form.Control type="text" name="state" onChange={formik.handleChange} isInvalid={formik.errors?.state} placeholder="State" />
                        </Form.Group>
                        <Form.Group controlId="formBasicText">
                            <Form.Control type="text" name="district" onChange={formik.handleChange} isInvalid={formik.errors?.district} placeholder="District" />
                        </Form.Group>
                        <Form.Group controlId="formBasicText">
                            <Form.Control type="text" name="phone" onChange={formik.handleChange} isInvalid={formik.errors?.phone} placeholder="Phone" />
                        </Form.Group>
                        <Form.Group controlId="formBasicText">
                            <Form.Control type="text" name="pincode" onChange={formik.handleChange} isInvalid={formik.errors?.pincode} placeholder="Pincode" />
                        </Form.Group>




                    </div>
                    {/* <div className="register-box-info">
          <Form.Check label="" name="group1" />
          <p className="sign-up-text">I agree to the<span>Terms & Conditions</span></p>
        </div> */}
                    <button className="button" type='submit' disabled={disableBtn}>Create Address</button>
                </Form>
            </div>
        </div>
    )
}

export default AddressModal