import React, { Fragment, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Layout from '../components/layout';
import LottieAnimation from './lottie-animation';
import { deleteNotification, getNotificationList, markAllAsReadNotification } from './Redux/Notification/action';

const Viewallnotification = () => {

    const [notification, setNotification] = useState([])

    useEffect(() => {
        getNotificationList('',true)((response) => {
            if (response && response.status) {
                setNotification(response?.data?.results)
            }
        });
    }, []);
    const onClickDeleteNotification = (id) => {
        deleteNotification(id)((response) => {
            if (response && response.status) {
                getNotificationList()((response) => {
                    if (response && response.status) {
                        setNotification(response?.data?.results)
                    }
                });
            }
        });
    }
    const onClickmarkAllAsRead = () => {
        markAllAsReadNotification()((response) => {
            if (response && response.status) {
                getNotificationList()((response) => {
                    if (response && response.status) {
                        setNotification(response?.data?.results)
                    }
                });
            }
        });
    }
    return (
        <React.Fragment>
            <Layout>
                <section>
                    <div className='container min-height-400'>
                        <div className="row notification-container">
                            <h2 className="text-center">My Notifications</h2>
                            {notification?.length == 0 ?
                                <Fragment>
                                    <div className='mt-5'>
                                        <LottieAnimation />
                                    </div>
                                </Fragment>
                                :
                                <Fragment>
                                    <p className="dismiss"><Button className="dismiss-all" onClick={() => onClickmarkAllAsRead()}>Read All</Button></p>
                                    <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                                        {notification?.map((notification_data, index) =>
                                            <div className="card notification-card notification-invitation" key={index} style={{backgroundColor : notification_data?.is_read ? '#fff':'#dcdcdc'}} >
                                                <div className="card-body">
                                                    <table>
                                                        <tr>
                                                            <div className='card-notification-main'>
                                                                <td><div className="card-title">{notification_data?.title}</div></td>
                                                                <td><div className="card-desc">{notification_data?.description}</div></td>
                                                            </div>
                                                            <td>
                                                                <Button className="btn btn-danger dismiss-notification" onClick={() => onClickDeleteNotification(notification_data?.id)}>Delete</Button>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        )}

                                    </div>
                                </Fragment>
                            }
                        </div>
                    </div>
                </section>
            </Layout>
        </React.Fragment>
    )
}

export default Viewallnotification;