import { CREATE_CONSULTATION_URL, GET_PRODUCT_DETAILS } from "../../api";
import axiosInstance from "../../axiosInstance";

export const getProductDetails = (plan_id, user_id) => onResponse => {
    try {
        console.log('here');
        var BASE_URL = GET_PRODUCT_DETAILS + '?plan_id=' + plan_id;
        if (user_id && user_id != undefined && user_id != null) {
            BASE_URL += '&user_id=' + user_id
        }
        axiosInstance.get(BASE_URL)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getAllProductDetails = (page, limit, search, direction, property_type, width, length, back_width, left_length, diagonal_length, bed_room, bath_room, balcony, car_parking, pooja_room, drawing_area, from_price, to_price) => onResponse => {
    try {
        let BASE_URL = GET_PRODUCT_DETAILS + '?page=' + page + '&limit=' + limit;
        if (search && search != '' && search != undefined) {
            BASE_URL += '&search=' + search
        }
        if (width && width != '' && width != undefined) {
            BASE_URL += '&width=' + width
        }
        if (length && length != '' && length != undefined) {
            BASE_URL += '&length=' + length
        }
        if (back_width && back_width != '' && back_width != undefined) {
            BASE_URL += '&back_width=' + back_width
        }
        if (left_length && left_length != '' && left_length != undefined) {
            BASE_URL += '&left_length=' + left_length
        }
        if (diagonal_length && diagonal_length != '' && diagonal_length != undefined) {
            BASE_URL += '&diagonal_length=' + diagonal_length
        }
        if (direction && direction != '' && direction != undefined) {
            BASE_URL += '&direction=' + direction
        }
        if (property_type && property_type != '' && property_type != undefined) {
            BASE_URL += '&property_id=' + property_type
        }
        if (bed_room && bed_room != '' && bed_room != undefined) {
            BASE_URL += '&bed_rooms=' + bed_room
        }
        if (bath_room && bath_room != '' && bath_room != undefined) {
            BASE_URL += '&bath_rooms=' + bath_room
        }
        if (balcony && balcony != '' && balcony != undefined) {
            BASE_URL += '&balcony=' + balcony
        }
        if (drawing_area && drawing_area != '' && drawing_area != undefined) {
            BASE_URL += '&drawing_area=' + drawing_area
        }
        if (pooja_room && pooja_room != '' && pooja_room != undefined) {
            BASE_URL += '&prayer_room=' + pooja_room
        }
        if (car_parking && car_parking != '' && car_parking != undefined) {
            BASE_URL += '&car_parking=' + car_parking
        }
        if (from_price != undefined) {
            BASE_URL += '&from_price=' + from_price
        }
        if (to_price != undefined) {
            BASE_URL += '&to_price=' + to_price
        }
        axiosInstance.get(BASE_URL)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const createConsultation = (props) => onResponse => {
    try {

        axiosInstance.post(CREATE_CONSULTATION_URL, props?.formData)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};