

// export const BASE_URL = 'http://localhost:3000'
// export const BASE_URL_DESIGN = 'http://localhost:3000'
export const BASE_URL = 'https://design.stikcon.com'
export const BASE_URL_DESIGN = 'https://interior.stikcon.com'
// export const BASE_URL = 'https://stkecom.aventusinformatics.com'
// export const BASE_URL_DESIGN = 'https://stkdesign.aventusinformatics.com'

//Auth
export const CUSTOMER_REGISTER_URL = 'auth/customer/register-customer'
export const CUSTOMER_VERIFY_OTP_URL = 'auth/customer/register-verify-otp-customer'
export const CUSTOMER_LOGIN_URL = 'auth/customer/login-customer'
export const CUSTOMER_LOGIN_VERIFY_OTP_URL = 'auth/customer/login-otp-customer'

//profile
export const GET_CUSTOMER_PROFILE_URL = 'user/profile/get-profile-details'
export const CREATE_CUSTOMER_ADDRESS_URL = 'user/user-address/create-or-update-user-address'
export const DELETE_CUSTOMER_PROFILE_URL = 'user/profile/destroy-my-account'
export const UPDATE_CUSTOMER_PROFILE_URL = 'user/profile/update-my-account'
export const DELETE_CUSTOMER_ADDRESS_URL = 'user/user-address/destroy-user-address'
export const UPDATE_CUSTOMER_EMAIL_NOTIFICATION_URL = 'user/profile/allow-notification'
export const UPDATE_CUSTOMER_PRIMARY_ADDRESS_URL = 'user/user-address/change-user-address-primary'

// Product
export const GET_PRODUCT_DETAILS = 'homeplan/HomePlan/list-customer-homeplan'

// razorpay
export const ADD_PAYMENT_RAZORPAY = 'order/razorpay/get-razorpay';

//faq
export const LIST_FAQ_URL = 'faq/Faq/Get-faq-listing'

//home
export const CREATE_CONTACT_US_URL = 'faq/contactus/create-update-contactus'

//notification
export const GET_NOTIFICATION_LIST = 'notificationNotification/get-listing-notification/';
export const GET_NOTIFICATION_DELETE = 'notificationNotification/destroy-notification/';
export const MARK_ALL_AS_READ_NOTIFICATION_URL = 'notificationNotification/disable-notification/';

//design
export const GET_CUSTOMER_WISHLIST_URL = 'wishlist/wishlist/get-wishlist'
export const LIST_CUSTOMER_DESIGNS_URL = 'homeplan/HomePlan/list-customer-my-homeplan-designs'
export const WISHLIST_CUSTOMER_DESIGNS_URL = 'wishlist/wishlist/add-to-wishlist'
export const REMOVE_WISHLIST_CUSTOMER_DESIGNS_URL = 'wishlist/wishlist/remove-from-wishlist'

//payment
export const GET_PAYMENT_LIST_URL = 'order/order/get-order-details'

// order payment
export const ORDER_PAYMENT = 'order/order/order-payment';
export const ORDER_PAYMENT_DETAILS_URL = 'order/order/get-invoice';
export const DOWNLOAD_ORDER_PAYMENT_URL = 'order/order/invoice-download';
// testimonial
export const GET_TESTIMONIAL_DETAILS = 'our-designersdesigners/get-our-testimonial';

//interior design
export const LIST_CATEGORY_ROOM_URL = 'category/rooms/listing-category-rooms'

//property types
export const LIST_PROPERTY_TYPES_URL = 'homeplan/property/get-customer-property'

//directions
export const GET_DIRECTIONS_DROPDOWN_URL = 'homeplan/directions/get-dropdown-direction'

//consultation
export const CREATE_CONSULTATION_URL = 'faq/contactus/create-update-consultation'


//need support
export const CREATE_NEED_SUPPORT_URL = 'faq/contactus/create-update-need-support'

//solutions
export const LIST_SOLUTIONS_URL = 'homeplan/solution/get-solution'


//brochure
export const LIST_BROCHURE_URL = 'faq/brochure/get-brochure-listing'

